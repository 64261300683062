import { en } from "./i18n/en";
import { es } from "./i18n/es";

export const translationsChimpa =
{
    en: {
        translation: en
    },
    es: {
        translation: es
    }
}