import React from "react";

export default function SeccionExtensionesVisuales({ proyecto }) {

  console.log(!!proyecto.extencionesVisuales,'PRIMERRR')
  console.log(proyecto.extencionesVisuales,'seggg')
  console.log(typeof proyecto.extencionesVisuales,'aaaaaaaaaaaaaaaaa');
  return(<>
      {proyecto.extencionesVisuales ? 
      <>
      <div
      className="paleta-titulo d-flex flex-column justify-content-center flex-wrap"
      style={{
        backgroundColor: proyecto.bg_s_extVisuales,
      }}
    >
      <h1
        className="detalle-proyecto-titulo"
        style={{ fontFamily: `'${proyecto.fuente[0].fontFamily}', sans-serif`, color: proyecto.color_s_extVisuales }}
      >
        Extenciones Visuales
      </h1>
    </div>
    <div className="bg-light">
    {typeof proyecto.extencionesVisuales === 'object' ?  <div className="row">
      {proyecto.extencionesVisuales?.map((item,i)=>{
        
        return(<div
        className="col-md-6"
        key={i}
        style={{ color: `#${proyecto.paleta[0].HTML}` }}
      >
        <img src={item} alt="Imagen" className="img-fluid" />
      </div>)          
      })}
    </div> : <div className="d-flex justify-content-center align-items-center p-5">
      <img src={proyecto.extencionesVisuales} alt="Imagen" className="img-fluid" />
    </div>}
    </div> </>: <></>}
    </>)
 
}
