import portadaSingulares from "./img/DetalleProyectos/SingularesDetalle/Singulares Portada.png";
import sliderSingulares1 from "./img/DetalleProyectos/SingularesDetalle/bg-portfolio-branding.jpg";
import {
  singulares_1,
  singulares_2,
  singulares_3,
  singulares_4,
  singulares_5,
  singulares_6,
  singulares_7,
  singulares_8,
  singulares_9,
  singulares_10,
  singulares_11,
  singulares_12,
  fotos_singulares_1,
  fotos_singulares_2,
  fotos_singulares_3,
  extenciones_visuales,
  singulares_header_1,
  singulares_header_2,
  singulares_header_3,
} from "./img/DetalleProyectos/SingularesDetalle/index.js";

import {
  delGredalPortada,
  delGredalHeader,
  fotos_delGredal_1,
  fotos_delGredal_2,
  delGredal_1,
  delGredal_2,
  delGredal_3,
  delGredal_4,
  delGredal_5,
  delGredal_6,
  delGredal_7,
  delGredal_extensionesVisuales,
} from "./img/DetalleProyectos/DelGredalDetalle";

import {
  linetec_header,
  linetec_portada,
  linetec_extensionesVisuales_1,
  linetec_1,
  linetec_2,
  linetec_3,
  linetec_4,
  linetec_5,
  linetec_6,
  linetec_7,
  linetec_8,
  linetec_9,
  fotos_linetec_1,
} from "./img/DetalleProyectos/Branding-Linetec/index.js";

import {
  analitica_header_1,
  analitica_header_2,
  analitica_header_3,
  analitica_portada,
  analitica_extensionesVisuales,
  analitica_1,
  analitica_2,
  analitica_3,
  analitica_4,
  analitica_5,
  fotos_analitica_1,
  fotos_analitica_2,
  fotos_analitica_3,
  fotos_analitica_4,
} from "./img/DetalleProyectos/Branding-Analitica/index.js";

import {
  altaCuchaPortada,
  altaCuchaHeader_1,
  altaCuchaHeader_2,
  foto_altaCucha_1,
} from "./img/DetalleProyectos/Branding-Alta Cucha/index.js";

import {
  GusEspada_header_1,
  GusEspada_portada,
  GusEspada_extensionesVisuales,
  fotos_GusEspada_1,
  GusEspada_1,
  GusEspada_2,
  GusEspada_3,
  GusEspada_4,
  GusEspada_5
} from "./img/DetalleProyectos/Branding-Gus Espada/index.js";

import {
  bultako_header_1,
  bultako_portada,
  bultako_extensionesVisuales,
  fotos_bultako_1,
  fotos_bultako_2,
  bultako_1,
  bultako_2,
} from "./img/DetalleProyectos/Branding-Bultako/index.js";

import {
  papeles_header_1,
  papeles_portada,
  papeles_extensionesVisuales,
  fotos_papeles_1,
  fotos_papeles_2,
  papeles_1,
  papeles_5,
  papeles_6,
  papeles_7,
  papeles_8,
  papeles_9
} from "./img/DetalleProyectos/Branding-Papele.net/index.js";

import {
  rio2_header_1,
  rio2_portada,
  rio2_extensionesVisuales,
  fotos_rio2_1,
  fotos_rio2_2,
  rio2_1,
  rio2_2,
  rio2_3,
  rio2_4,
} from "./img/DetalleProyectos/Branding-Rio Segundo";

import {
  delivery_header_1,
  delivery_portada,
  delivery_extensionesVisuales,
  fotos_delivery_1,
  fotos_delivery_2,
  delivery_1,
  delivery_2,
  delivery_3,
  delivery_4,
  delivery_5,
  delivery_6,
} from "./img/DetalleProyectos/Delivery was/index.js";

const proyectos = [
  {
    url: "singulares",
    titulo: "Singulares",
    imgPortada: portadaSingulares,
    slider: [singulares_header_1, singulares_header_2, singulares_header_3],
    fill:"#ffffff",
    bg_s_paleta: "#49C7EE",
    color_s_paleta: "#ffffff",
    bg_s_tipografia: "#EE3F23",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#003E51",
    bg_s_fotografia: "#003E51",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#003E51",
    color_s_extVisuales: "ffffff",
    bg_s_piezasGraficas: "#49C7EE",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:100% M:67% Y:48% K:38%",
        RGB: " R:0% G:62% B:81%",
        HTML: "003E51",
      },
      {
        CMYK: " C:0% M:90% Y:100% K:0%",
        RGB: " R:238% G:63% B:35%",
        HTML: "EE3F23",
      },
      {
        CMYK: " C:59% M:0% Y:2% K:0%",
        RGB: " R:73% G:199% B:238%",
        HTML: "49C7EE",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:0%",
        RGB: " R:255% G:255% B:255%",
        HTML: "FFFFFF",
      },
    ],
    fuente: [
      {
        fontFamily: "Axiforma",
        ejemplosFuentes: [
          { tipoFuente: "Light", nombreFuente: "Axiforma Light" },
          { tipoFuente: "Regular", nombreFuente: "Axiforma" },
          { tipoFuente: "Medium", nombreFuente: "Axiforma Medium" },
          { tipoFuente: "Bold", nombreFuente: "Axiforma Bold" },
        ],
      },
      {
        fontFamily: "Lora",
        ejemplosFuentes: [
          { tipoFuente: "Regular", nombreFuente: "Lora" },
          { tipoFuente: "Medium", nombreFuente: "Lora Medium" },
          { tipoFuente: "Bold", nombreFuente: "Lora Bold" },
        ],
      },
    ],
    galeria: [
      {
        img: fotos_singulares_1,
        titulo: "Fotografías a color",
        texto:
          "El usos de fotografías a color, se deberá implementar en imágenes que hagan referencia a situaciones en el ámbito laboral.",
      },
      {
        img: fotos_singulares_2,
        titulo: "Fotografías en blanco y negro",
        texto:
          "El usos de fotografías en blanco y negro, estará destinado principalmente cuando se implementen imágenes de los?las integrantes del equipo, o imágenes que hagan alusión al público objetivo.",
      },
      {
        img: fotos_singulares_3,
        titulo: "Fotografías",
        texto:
          "El usos de fotografías en duotono, estará destinado principalmente cuando se utilicen imágenes que hagan alusión al público objetivo e imágenes ilustrativas. Se recomienda el uso de este tratamiento fotográfico solo para piezas gráficas digitales.",
      },
    ],
    extencionesVisuales: extenciones_visuales,
    piezasGraficas: [
      singulares_1,
      singulares_2,
      singulares_3,
      singulares_4,
      singulares_5,
      singulares_6,
      singulares_7,
      singulares_8,
      singulares_9,
      singulares_10,
      singulares_11,
      singulares_12,
    ],
  },
  {
    url: "del-gredal",
    titulo: "Del Gredal",
    imgPortada: delGredalPortada,
    slider: delGredalHeader,
    fill:"#ffffff",
    bg_s_paleta: "#000000",
    color_s_paleta: "#ffffff",
    bg_s_tipografia: "#E35525",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#000000",
    bg_s_fotografia: "#4AA39C",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#E35525",
    color_s_extVisuales: "ffffff",
    bg_s_piezasGraficas: "#4AA39C",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:75% M:68% Y:67% K:90%",
        RGB: " R:0% G:0% B:0%",
        HTML: "000000",
      },
      {
        CMYK: " C:71% M:17% Y:42% K:0%",
        RGB: " R:74% G:163% B:156%",
        HTML: "4AA39C",
      },
      {
        CMYK: " C:6% M:81% Y:100% K:0%",
        RGB: " R:227% G:85% B:37%",
        HTML: "E35525",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:0%",
        RGB: " R:255% G:255% B:255%",
        HTML: "FFFFFF",
      },
    ],
    fuente: [
      {
        fontFamily: "Axiforma",
        ejemplosFuentes: [
          { tipoFuente: "Light", nombreFuente: "Axiforma Light" },
          { tipoFuente: "Regular", nombreFuente: "Axiforma" },
          { tipoFuente: "Medium", nombreFuente: "Axiforma Medium" },
          { tipoFuente: "Bold", nombreFuente: "Axiforma Bold" },
        ],
      },
      {
        fontFamily: "Helvetica Neue LT Std",
        ejemplosFuentes: [
          { tipoFuente: "Light", nombreFuente: "Helvetica Neue LT Std Light" },
          { tipoFuente: "Regular", nombreFuente: "Helvetica Neue LT Std" },
          {
            tipoFuente: "Medium",
            nombreFuente: "Helvetica Neue LT Std Medium",
          },
          { tipoFuente: "Bold", nombreFuente: "Helvetica Neue LT Std Bold" },
        ],
      },
      {
        fontFamily: "Futura",
        ejemplosFuentes: [
          { tipoFuente: "Medium", nombreFuente: "Futura Medium" },
          { tipoFuente: "Bold", nombreFuente: "Futura Bold" },
        ],
      },
      {
        fontFamily: "Monument Extended",
        ejemplosFuentes: [
          { tipoFuente: "Regular", nombreFuente: "Monument Extended Regular" },
        ],
      },
    ],
    galeria: [
      {
        img: fotos_delGredal_1,
        titulo: "Fotografías a color",
        texto:
          "El uso de fotografías a color, se deberá implementar en imágenes que hagan referencia a la geografía, y naturaleza del lugar.",
      },
      {
        img: fotos_delGredal_2,
        titulo: "Fotografías en blanco y negro",
        texto:
          "El uso de fotografía en blanco y negro, estará destinado principalmente cuando se utilicen imágenes de personas y momentos compartidos que apelen a la emotividad.",
      },
    ],
    extencionesVisuales: delGredal_extensionesVisuales,
    piezasGraficas: [
      delGredal_1,
      delGredal_2,
      delGredal_3,
      delGredal_4,
      delGredal_5,
      delGredal_6,
      delGredal_7,
    ],
  },
  {
    url: "alta-cucha",
    titulo: "Alta Cucha",
    slider: altaCuchaHeader_1,
    fill:"#ffffff",
    bg_s_paleta: "#E5E4DF",
    color_s_paleta: "#B85F28",
    bg_s_tipografia: "#B85F28",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#B85F28",
    bg_s_fotografia: "#E5E4DF",
    color_s_fotografia: "#B85F28",
    bg_s_extVisuales: "#E35525",
    color_s_extVisuales: "ffffff",
    bg_s_piezasGraficas: "#B85F28",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:0% M:0% Y:3% K:10%",
        RGB: " R:229% G:228% B:223%",
        HTML: "E5E4DF",
      },
      {
        CMYK: " C:0% M:48% Y:78% K:28%",
        RGB: " R:184% G:95% B:40%",
        HTML: "B85F28",
      },
      {
        CMYK: " C:0% M:0% Y:3% K:10%",
        RGB: " R:229% G:228% B:223%",
        HTML: "E5E4DF",
      },
    ],
    fuente: [
      {
        fontFamily: "Junegull rg",
        ejemplosFuentes: [
          { tipoFuente: `abcdefghijklmñopqrstuvwxyz 0123456789 "!·$%&&/= ?¿`, nombreFuente: "Junegull rg" },
          
        ],
      },
    ],
    galeria: [
      {
        img: foto_altaCucha_1,
      },
    ],
    piezasGraficas: [altaCuchaPortada],
  },
  {
    url: "linetec",
    titulo: "Linetec",
    imgPortada: linetec_portada,
    slider: linetec_header,
    fill:"#ffffff",
    bg_s_paleta: "#231E47",
    color_s_paleta: "#FFFFFF",
    bg_s_tipografia: "#231E47",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#1B1B3B",
    bg_s_fotografia: "#231E47",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#231E47",
    color_s_extVisuales: "ffffff",
    bg_s_piezasGraficas: "#231E47",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:93% M:99% Y:35% K:41%",
        RGB: " R:35% G:30% B:71%",
        HTML: "231E46",
      },
      {
        CMYK: " C:15% M:93% Y:73% K:4%",
        RGB: " R:201% G:45% B:60%",
        HTML: "C82D3B",
      },
      {
        CMYK: " C:85% M:54% Y:0% K:0%",
        RGB: " R:24% G:109% B:117%",
        HTML: "086CB0",
      },
      {
        CMYK: " C:68% M:0% Y:52% K:0%",
        RGB: " R:83% G:180% B:143%",
        HTML: "52B48F",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:40%",
        RGB: " R:177% G:179% B:180%",
        HTML: "B1B2B4",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:100%",
        RGB: " R:26% G:23% B:27%",
        HTML: "1A171B",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:0%",
        RGB: " R:255% G:255% B:255%",
        HTML: "FFFFFF",
      },
    ],
    fuente: [
      {
        fontFamily: "Reef",
        ejemplosFuentes: [{ tipoFuente: " ", nombreFuente: " " }],
      },
      {
        fontFamily: "DIN Pro",
        ejemplosFuentes: [{ tipoFuente: " ", nombreFuente: " " }],
      },
    ],
    galeria: [
      {
        img: fotos_linetec_1,
        titulo: "",
        texto:
          "Uso de recurso fotográfico de forma limpia. Fotografías simples y expresivas afines a la identidad visual planteada. Se admite el uso de filtros en las tonalidades de la paleta institucional, para evitar contrastes desfavorables. Las fotografías deben ayudar a ilustrar los productos y servicios que brinda Linetec.",
      },
    ],
    extencionesVisuales: linetec_extensionesVisuales_1,
    piezasGraficas: [
      linetec_1,
      linetec_2,
      linetec_3,
      linetec_4,
      linetec_5,
      linetec_6,
      linetec_7,
      linetec_8,
      linetec_9,
    ],
  },
  {
    url: "analitica427",
    titulo: "Analitica 427",
    imgPortada: analitica_portada,
    slider: [analitica_header_1,
      analitica_header_2,
      analitica_header_3],
      fill:"#000000",
    bg_s_paleta: "#172A41",
    color_s_paleta: "#FFFFFF",
    bg_s_tipografia: "#172A41",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#003E51",
    bg_s_fotografia: "#172A41",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#172A41",
    color_s_extVisuales: "ffffff",
    bg_s_piezasGraficas: "#172A41",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:65% M:35% Y:0% K:75%",
        RGB: " R:23% G:42% B:65%",
        HTML: "172A41",
      },
      {
        CMYK: " C:50% M:8% Y:0% K:14%",
        RGB: " R:111% G:203% B:220%",
        HTML: "6FCBDC",
      },
      {
        CMYK: " C:74% M:47% Y:0% K:35%",
        RGB: " R:44% G:89% B:167%",
        HTML: "2C59A7",
      },
      {
        CMYK: " C:34% M:0% Y:63% K:29%",
        RGB: " R:121% G:182% B:67%",
        HTML: "79B643",
      },
    ],
    fuente: [
      {
        fontFamily: "Bahnschrift",
        ejemplosFuentes: [
          { tipoFuente: "Light", nombreFuente: "Bahnschrift Light" },
          { tipoFuente: "Regular", nombreFuente: "Bahnschrift" },
          { tipoFuente: "Medium", nombreFuente: "Bahnschrift Medium" },
          { tipoFuente: "Bold", nombreFuente: "Bahnschrift Bold" },
        ],
      },
    ],
    galeria: [
      {
        img: fotos_analitica_1,
      },
      {
        img: fotos_analitica_2,
      },
      {
        img: fotos_analitica_3,
      },
      {
        img: fotos_analitica_4,
      },
    ],
    extencionesVisuales: analitica_extensionesVisuales,
    piezasGraficas: [
      analitica_1,
      analitica_2,
      analitica_3,
      analitica_4,
      analitica_5,
    ],
  },
  {
    url: "gus-espada",
    titulo: "Gus Espada",
    imgPortada: GusEspada_portada,
    slider: GusEspada_header_1,
    fill:"#ffffff",
    bg_s_paleta: "#819278",
    color_s_paleta: "#FFFFFF",
    bg_s_tipografia: "#819278",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#474747",
    bg_s_fotografia: "#819278",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#819278",
    color_s_extVisuales: "ffffff",
    bg_s_piezasGraficas: "#819278",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:12% M:0% Y:18% K:43%",
        RGB: " R:129% G:146% B:120%",
        HTML: "819278",
      },
      {
        CMYK: " C:0% M:2% Y:13% K:42%",
        RGB: " R:147% G:144% B:128%",
        HTML: "939080",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:72%",
        RGB: " R:71% G:71% B:71%",
        HTML: "474747",
      },
      {
        CMYK: " C:0% M:0% Y:19% K:3%",
        RGB: " R:247% G:248% B:202%",
        HTML: "F7F8CA",
      },
      {
        CMYK: " C:0% M:0% Y:25% K:20%",
        RGB: " R:204% G:204% B:153%",
        HTML: "CCCC99",
      },
      {
        CMYK: " C:4% M:15% Y:0% K:89%",
        RGB: " R:26% G:23% B:27%",
        HTML: "1A171B",
      },
      {
        CMYK: " C:0% M:0% Y:1% K:4%",
        RGB: " R:244% G:245% B:242%",
        HTML: "F4F5F2",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:0%",
        RGB: " R:255% G:255% B:255%",
        HTML: "FFFFFF",
      },
    ],
    fuente: [
      {
        fontFamily: "Cinzel",
        ejemplosFuentes: [
          { tipoFuente: "Regular", nombreFuente: "Cinzel" },
          { tipoFuente: "Medium", nombreFuente: "Cinzel Medium" },
          { tipoFuente: "Semibold", nombreFuente: "Cinzel Semibold" },
          { tipoFuente: "Bold", nombreFuente: "Cinzel Bold" },
        ],
      },
      {
        fontFamily: "Poppins",
        ejemplosFuentes: [
          { tipoFuente: "Regular", nombreFuente: "Poppins" },
          { tipoFuente: "Medium", nombreFuente: "Poppins Medium" },
          { tipoFuente: "Semibold", nombreFuente: "Poppins Semibold" },
          { tipoFuente: "Bold", nombreFuente: "Poppins Bold" },
        ],
      },
    ],
    galeria: [
      {
        img: fotos_GusEspada_1,
        titulo: "Fotografías a color",
        texto:
          "Uso de recurso fotográfico de forma limpia. Fotografías simples y expresivas con tonos afines a la identidad visual planteada. Sin demasiado ruido visual ni altos contrastes. Se admite el uso de filtros sepias, fotografías en blanco y negro.",
      },
    ],
    extencionesVisuales: GusEspada_extensionesVisuales,
    piezasGraficas: [
      GusEspada_1,
      GusEspada_2,
      GusEspada_3,
      GusEspada_4,
      GusEspada_5
    ],
  },
  {
    url: "bultako",
    titulo: "Bultako",
    imgPortada: bultako_portada,
    slider: bultako_header_1,
    fill:"#ffffff",
    bg_s_paleta: "#1B1B3B",
    color_s_paleta: "#FFFFFF",
    bg_s_tipografia: "#1B1B3B",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#1B1B3B",
    bg_s_fotografia: "#1B1B3B",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#1B1B3B",
    color_s_extVisuales: "#01E60A",
    bg_s_piezasGraficas: "#1B1B3B",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:54% M:54% Y:0% K:77%",
        RGB: " R:27% G:27% B:59%",
        HTML: "1B1B3B",
      },
      {
        CMYK: " C:100% M:0% Y:96% K:10%",
        RGB: " R:1% G:230% B:10%",
        HTML: "01E60A",
      },
    ],
    fuente: [
      {
        fontFamily: "Righteous",
        ejemplosFuentes: [{ tipoFuente: " ", nombreFuente: " " }],
      },
    ],
    galeria: [
      {
        img: fotos_bultako_1,
      },
      {
        img: fotos_bultako_2,
      },
    ],
    extencionesVisuales: bultako_extensionesVisuales,
    piezasGraficas: [bultako_1, bultako_2],
  },
  {
    url: "papeles-net",
    titulo: "Papeles.net",
    imgPortada: papeles_portada,
    slider: papeles_header_1,
    fill:"#000000",
    bg_s_paleta: "#292F76",
    color_s_paleta: "#FFFFFF",
    bg_s_tipografia: "#292F76",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#292F76",
    bg_s_fotografia: "#292F76",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#292F76",
    color_s_extVisuales: "#ffffff",
    bg_s_piezasGraficas: "#292F76",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:95% M:96% Y:19% K:0%",
        RGB: " R:41% G:47% B:118%",
        HTML: "292F76",
      },
      {
        CMYK: " C:95% M:12% Y:2% K:0%",
        RGB: " R:16% G:150% B:212%",
        HTML: "1096D4",
      },
      {
        CMYK: " C:40% M:0% Y:100% K:0%",
        RGB: " R:189% G:212% B:12%",
        HTML: "BDD40C",
      },
    ],
    fuente: [
      {
        fontFamily: "Montserrat",
        ejemplosFuentes: [
          { tipoFuente: "Light", nombreFuente: "Montserrat Light" },
          { tipoFuente: "Regular", nombreFuente: "Montserrat" },
          { tipoFuente: "Medium", nombreFuente: "Montserrat Medium" },
          { tipoFuente: "Bold", nombreFuente: "Montserrat Bold" },
        ],
      },
    ],
    galeria: [
      {
        img: fotos_papeles_1,
      },
      {
        img: fotos_papeles_2,
      },
    ],
    extencionesVisuales: papeles_extensionesVisuales,
    piezasGraficas: [
      papeles_1,
      papeles_5,
      papeles_6,
      papeles_7,
      papeles_8,
      papeles_9
    ],
  },
  {
    url: "municipalidad-rio-II",
    titulo: "Mucipalidad Rio 2",
    imgPortada: rio2_portada,
    slider: rio2_header_1,
    fill:"#000000",
    bg_s_paleta: "#003C87",
    color_s_paleta: "#FFFFFF",
    bg_s_tipografia: "#003C87",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#003C87",
    bg_s_fotografia: "#003C87",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#003C87",
    color_s_extVisuales: "ffffff",
    bg_s_piezasGraficas: "#003C87",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:100% M:56% Y:0% K:47%",
        RGB: " R:0% G:60% B:135%",
        HTML: "003C87",
      },
      {
        CMYK: " C:70% M:17% Y:0% K:0%",
        RGB: " R:47% G:168% B:223%",
        HTML: "2FA8DF",
      },
      {
        CMYK: " C:2% M:98% Y:95% K:0%",
        RGB: " R:233% G:36% B:41%",
        HTML: "E92429",
      },
      {
        CMYK: " C:0% M:34% Y:100% K:2%",
        RGB: " R:250% G:165% B:0%",
        HTML: "FAA500",
      },
      {
        CMYK: " C:75% M:68% Y:67% K:90%",
        RGB: " R:0% G:0% B:0%",
        HTML: "000000",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:0%",
        RGB: " R:255% G:255% B:255%",
        HTML: "FFFFFF",
      },
    ],
    fuente: [
      {
        fontFamily: "Gotham",
        ejemplosFuentes: [
          { tipoFuente: "Light", nombreFuente: "Gotham Light" },
          { tipoFuente: "Regular", nombreFuente: "Gotham" },
          { tipoFuente: "Medium", nombreFuente: "Gotham Medium" },
          { tipoFuente: "Bold", nombreFuente: "Gotham Bold" },
        ],
      },
    ],
    galeria: [
      {
        img: fotos_rio2_1,
      },
      {
        img: fotos_rio2_2,
      },
    ],
    extencionesVisuales: rio2_extensionesVisuales,
    piezasGraficas: [
      rio2_1,
      rio2_2,
      rio2_3,
      rio2_4,
    ],
  },
  {
    url: "delivery-wap",
    titulo: "Delivery wap",
    imgPortada: delivery_portada,
    slider: delivery_header_1,
    fill:"#ffffff",
    bg_s_paleta: "#3366FF",
    color_s_paleta: "#FFFFFF",
    bg_s_tipografia: "#3366FF",
    color_s_tipografia: "#ffffff",
    color_text_tipografia: "#3366FF",
    bg_s_fotografia: "#3366FF",
    color_s_fotografia: "#ffffff",
    bg_s_extVisuales: "#3366FF",
    color_s_extVisuales: "#FFFFFF",
    bg_s_piezasGraficas: "#292F76",
    color_s_piezasGraficas: "ffffff",
    paleta: [
      {
        CMYK: " C:80% M:60% Y:0% K:0%",
        RGB: " R:51% G:102% B:255%",
        HTML: "3366FF",
      },
      {
        CMYK: " C:67% M:0% Y:50% K:9%",
        RGB: " R:76% G:232% B:115%",
        HTML: "4CE873",
      },
      {
        CMYK: " C:0% M:0% Y:0% K:0%",
        RGB: " R:255% G:255% B:255%",
        HTML: "FFFFFF",
      },
    ],
    fuente: [
      {
        fontFamily: "Euclid Circular A",
        ejemplosFuentes: [
          { tipoFuente: "Light", nombreFuente: "Euclid Circular A Light" },
          { tipoFuente: "Regular", nombreFuente: "Euclid Circular A" },
          { tipoFuente: "Medium", nombreFuente: "Euclid Circular A Medium" },
          { tipoFuente: "Bold", nombreFuente: "Euclid Circular A Bold" },
        ],
      },
    ],
    galeria: [
      {
        img: fotos_delivery_1,
      },
      {
        img: fotos_delivery_2,
      },
    ],
    extencionesVisuales: delivery_extensionesVisuales,
    piezasGraficas: [
      delivery_1,
      delivery_2,
      delivery_3,
      delivery_4,
      delivery_5,
      delivery_6,
    ],
  },
];

export default proyectos;
