
import React, { useEffect, useState, useRef } from 'react';
import './styles.css'
import { useTranslation } from 'react-i18next';

export default function ServiciosMConversiones({services}) {

    const { t } = useTranslation()

    return (
        <section id="slider-desarrollo-mobile" className="position-relative d-block d-md-none">


            {
                services.map((service, index) => (
                    <div key={index} className="item item-mobile" data-hash="pagina-web">
                        <img src={service.bgMobile} className="bg" />
                        <div className="container d-flex   h-100">
                            <div className="row text-sliders">
                                <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                                    <h3 className="ff-circularBold">{t(service.name)}</h3>
                                    <div className="d-flex align-items-baseline mb-3 mt-3">
                                        <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                        <h4 className="ff-circularBold mb-0">{t(service.title)}</h4>
                                    </div>
                                    <h5 className="fc-yellow mb-4">{t(service.paragraph)}</h5>
                                    <a href="#form" className="btn btn-outline-primary">{t(service.button)}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            {/* <div className="item item-mobile" data-hash="pagina-web">
                <img src="/images/img/mockup/bg-slider-mobile-web.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h3 className="ff-circularBold">Pagina Web</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">Creamos tu <span className="fc-lightBlue">sitio web</span> a tu medida</h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Un sitio intuitivo, rápida y persona</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero digitalizarme</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item item-mobile" data-hash="ecommerce">
                <img src="/images/img/mockup/bg-slider-mobile-ecommerce.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h3 className="ff-circularBold">Ecommerce</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">Te ayudamos a despegar con el <span className="fc-lightBlue">e-commerce</span> que necesitas</h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Llega a nuevos mercados y genera nuevas estrategias</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero evolucionar</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item item-mobile" data-hash="plataforma">
                <img src="/images/img/mockup/bg-slider-mobile-plataformas.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h3 className="ff-circularBold">Plataforma</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">Desarrollamos tu  <span className="fc-lightBlue">plataforma</span> para que no dejes de crecer</h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Automatización e integración de procesos</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero crecer</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item item-mobile" data-hash="app">
                <img src="/images/img/mockup/bg-slider-mobile-app.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h3 className="ff-circularBold">App</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">
                                    Creamos tu <span className="fc-lightBlue">APP</span> para que la distancia no sea un
                                    problema.
                                </h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Automatización e integración de procesos</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero acercarme</a>
                        </div>
                    </div>
                </div>
            </div> */}

        </section>
    )
}