import chimpaLogo from './../../../assets/img/chimpance-logo.svg';
import Lottie from "lottie-react";
import bgJunglaMobile from './../../../assets/img/lotties/bgJunglaMobile.json';


export default function SectionFourteen(){
    return(
        <section id="testimonio-chimpa" className="d-flex align-items-center position-relative">
            <Lottie id="jungleMobile" autoplay={true} loop={true} mode={"normal"} rendererSettings={{preserveAspectRatio:"xMidYMid slice"}} animationData={bgJunglaMobile} background="transparent" className="d-block" />
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8 text-center position-relative">
                        <img loading="lazy" src={chimpaLogo} alt="" />
                        <div className="box text-center mt-5">
                            <h2 className="fc-yellow">“</h2>
                            <h4 className="ff-circularBook mb-0">Los Chimpas nunca nos quedamos quietos. Apuntamos siempre a
                                la innovación, el juego y el descubrimiento.<br />
                                Inventamos un millón de soluciones con tan solo un par de ramitas.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}