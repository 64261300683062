import React from 'react'
import btnPlus from './../../../assets/img/btn-plus.svg'
import btnMinus from './../../../assets/img/btn-minus.svg'

export default function SectionFive(){

    const [activeBox1, setActiveBox1] = React.useState(false);
    const [activeBox2, setActiveBox2] = React.useState(false);
    const [activeBox3, setActiveBox3] = React.useState(false);

    return(
        <section id="portfolio-card" className="d-block d-md-none position-relative">
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-12">
                        <div className={`box-colapse bg-02 ${activeBox1 ? 'boxActive' : ''}`} id="acordeonBox02" onClick={()=>setActiveBox1(!activeBox1)}>
                            <h1 className="mb-0">WEB</h1>
                            <h1 className="fb-transparent">UX UI</h1>
                            <img loading="lazy" src={btnPlus} className="btn-plus" />
                            <img loading="lazy" src={btnMinus} className="btn-minus" />
                            <div className="content">
                                <p>Creamos soluciones y experiencias digitales que enamoran (y convierten) clientes</p>
                            </div>
                            <div className="tag">
                                <span className="tag-outline me-2">Landing</span>
                                <span className="tag-outline me-2">E-commerce</span>
                                <span className="tag-outline me-2">App</span>
                                <span className="tag-outline me-2">Institucional</span>
                            </div>
                        </div>
                        <div className={`box-colapse bg-01 ${activeBox2 ? 'boxActive' : ''}`} id="acordeonBox01" onClick={()=>setActiveBox2(!activeBox2)}>
                            <h1 className="mb-0">BRANDING</h1>
                            <h1 className="fb-transparent">MARCAS</h1>
                            <img loading="lazy" src={btnPlus} className="btn-plus" />
                            <img loading="lazy" src={btnMinus} className="btn-minus" />
                            <div className="content">
                                <p>Construimos la identidad digital de tu marca resaltando su ADN y valores principales
                                </p>
                            </div>
                            <div className="tag">
                                <span className="tag-outline me-2">Naming</span>
                                <span className="tag-outline me-2">Papelería</span>
                                <span className="tag-outline me-2">Manual de marca</span>
                            </div>
                        </div>
                        <div className={`box-colapse bg-03 ${activeBox3 ? 'boxActive' : ''}`} id="acordeonBox03" onClick={()=>setActiveBox3(!activeBox3)}>
                            <h1 className="mb-0">CRO</h1>
                            <h1 className="fb-transparent">LEADS VENTAS</h1>
                            <img loading="lazy" src={btnPlus} className="btn-plus" />
                            <img loading="lazy" src={btnMinus} className="btn-minus" />
                            <div className="content">
                                <p>Atraemos clientes y ventas online diseñando campañas de marketing digital exitosas.
                                </p>
                            </div>
                            <div className="tag">
                                <span className="tag-outline me-2">Facebook Ads</span>
                                <span className="tag-outline me-2">Google Ads</span>
                                <span className="tag-outline me-2">WhatsApp</span>
                                <span className="tag-outline me-2">CRM</span>
                                <span className="tag-outline me-2">Analytics</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}