import React from 'react';

export function useInputField(initialValue = '', validate = () => true) {
  const [value, setValue] = React.useState(initialValue);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isValid, setIsValid] = React.useState(false);
  const [first, setClick] = React.useState(false)

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if(inputValue.length>0 ) setClick(true)
    setValue(inputValue);
    setIsEmpty(inputValue.trim() === '');
    setIsValid(validate(inputValue));
  };

  const handleBlur = () => {
    setIsEmpty(value.trim() === '');
    setIsValid(validate(value));
  };

  return {
    value,
    isEmpty,
    isValid,
    first, 
    handleChange,
    handleBlur,
  };
}
