import webVideo from './../../../assets/img/web-vertical.mp4'
import bgux from './../../../assets/img/bg-portfolio-ux-mobile.png'
import bgUi from './../../../assets/img/bg-portfolio-ui-mobile.png'
import bgBranding from './../../../assets/img/bg-portfolio-branding-mobile.png'
import bgMkt from './../../../assets/img/bg-portfolio-mkt-mobile.png'

import React from 'react';

export default function SectionSeven(){

    const [activeItem, setActiveItem] = React.useState('ux-item');

    return(
        <section id="portfolio-mobile" className="pt-100 pb-100 d-block d-md-none position-relative">
            <div className="conteiner-fluid">
                <div className="row">
                    <div className="col-12">
                        <h4 className="fc-lightBlue ff-circularBold mb-4 ms-3">PORTFOLIO</h4>
                        <div className="tab-menu">
                            <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation" type="button">
                                    <button className={`nav-link ${activeItem === 'ux-item' ? 'active' : ''}`} id="pills-ux-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-ux" type="button" role="tab" aria-controls="pills-ux"
                                        aria-selected={activeItem === 'ux-item' ? true : false} onClick={() => setActiveItem('ux-item')}>UX</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeItem === 'ui-item' ? 'active' : ''}`} id="pills-ui-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-ui" type="button" role="tab" aria-controls="pills-ui"
                                        aria-selected={activeItem === 'ui-item' ? true : false} onClick={() => setActiveItem('ui-item')}>UI</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeItem === 'web-item' ? 'active' : ''}`} id="pills-web-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-web" type="button" role="tab" aria-controls="pills-web"
                                        aria-selected={activeItem === 'web-item' ? true : false} onClick={() => setActiveItem('web-item')}>WEB</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeItem === 'branding-item' ? 'active' : ''}`} id="pills-branding-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-branding" type="button" role="tab"
                                        aria-controls="pills-branding" aria-selected={activeItem === 'branding-item' ? true : false} onClick={() => setActiveItem('branding-item')}>Branding</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeItem === 'mkt-item' ? 'active' : ''}`} id="pills-mkt-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-mkt" type="button" role="tab" aria-controls="pills-mkt"
                                        aria-selected={activeItem === 'mkt-item' ? true : false} onClick={() => setActiveItem('mkt-item')}>MKT Digital</button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className={`tab-pane fade ${activeItem === 'ux-item' ? 'show active' : ''}`} id="pills-ux" role="tabpanel"
                                aria-labelledby="pills-ux-tab">
                                <img loading={"lazy"} max-width="100%" height="auto"
                                    src={bgux} />
                            </div>
                            <div className={`tab-pane fade ${activeItem === 'ui-item' ? 'show active' : ''}`} id="pills-ui" role="tabpanel" aria-labelledby="pills-ui-tab">
                                <img loading={"lazy"} src={bgUi} width="100%" />
                            </div>
                            <div className={`tab-pane fade ${activeItem === 'web-item' ? 'show active' : ''}`} id="pills-web" role="tabpanel" aria-labelledby="pills-web-tab">
                                <video width="100%" muted autoPlay loop className="rounded-video">
                                    <source src={webVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div className={`tab-pane fade ${activeItem === 'branding-item' ? 'show active' : ''}`} id="pills-branding" role="tabpanel"
                                aria-labelledby="pills-branding-tab">
                                <img loading={"lazy"} src={bgBranding} width="100%" />
                            </div>
                            <div className={`tab-pane fade ${activeItem === 'mkt-item' ? 'show active' : ''}`} id="pills-mkt" role="tabpanel" aria-labelledby="pills-mkt-tab">
                                <img loading={"lazy"} src={bgMkt} width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}