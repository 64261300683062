import chimpaLogo from './../../../assets/img/chimpance-logo.svg'
import Lottie from "lottie-react";
import bgJunglaDesk from './../../../assets/img/lotties/bgJunglaDesk.json'
import bgJunglaMobile from './../../../assets/img/lotties/bgJunglaMobile.json'
import { useEffect, useRef } from 'react';

export default function SectionThirteen({lottieJungle}){
    const jungleLottieRef= useRef()
    const jungleLottieRef2= useRef()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: bgJunglaMobile,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice' // Adjust the aspect ratio to your needs
        }
      };

    useEffect(()=>{
        if(lottieJungle){
            jungleLottieRef.current.play()
            jungleLottieRef2.current.play()
        }else{
            jungleLottieRef.current.pause()
            jungleLottieRef2.current.pause()
        }
    },[lottieJungle])

    return(
        <section id="testimonio-chimpa" className="d-flex align-items-center position-relative">
            <Lottie lottieRef={jungleLottieRef2}  loop={true}  mode={"normal"} style={{opacity:"0.5"}} rendererSettings={{preserveAspectRatio:"xMidYMid slice"}} animationData={bgJunglaMobile} background="transparent" className="bg-lottie-jungle d-lg-none d-block position-absolute" />
            <Lottie lottieRef={jungleLottieRef} id="jungleMobile" autoplay={true} loop={true} mode={"normal"} rendererSettings={{preserveAspectRatio:"xMidYMid slice"}} animationData={bgJunglaDesk} background="transparent" className="d-lg-block d-none"  />
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8 text-center position-relative">
                        <img loading="lazy" src={chimpaLogo} alt="" />
                        <div className="box text-center mt-5">
                            <h2 className="fc-yellow">“</h2>
                            <h4 className="ff-circularBook mb-0">Los Chimpas nunca nos quedamos quietos. Apuntamos siempre a
                                la innovación, el juego y el descubrimiento.<br />
                                Inventamos un millón de soluciones con tan solo un par de ramitas.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}