import iconDots from './../../../assets/img/icon-dots.svg'
import arrowYellow from './../../../assets/img/icon-arrow-yellow.svg'

export default function SectionTwo(){
    return(
        <section id="posicionamiento" className="d-none d-md-block pt-100 pb-100 position-relative">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-9">
                        <div className="box">
                            <img loading={"lazy"} alt="dots" src={iconDots} className="img" />
                            <div className="d-flex justify-content-center align-items-baseline mb-3">
                                <img loading={"lazy"} src={arrowYellow} />
                                <h2 className="fc-lightBlue ms-3">Somos tu guía en la jungla digital</h2>
                            </div>
                            <h4 className="text-center mb-4">Agencia de Desarrollo Web, Diseño y Marketing Digital dedicada
                                a medianas y grandes empresas.</h4>
                            <ul className="p-0 m-0 d-flex justify-content-center">
                                <li id="web-hover" className="mx-2">
                                    <span  className="fs-20 ff-circularBold text-white">Web</span>
                                </li>
                                <li className="mx-2">
                                    <span  className="fs-20 ff-circularBold text-white">UX</span>
                                </li>
                                <li className="mx-2">
                                    <span  className="fs-20 ff-circularBold text-white">UI</span>
                                </li>
                                <li className="mx-2">
                                    <span  className="fs-20 ff-circularBold text-white">Branding</span>
                                </li>
                                <li className="mx-2">
                                    <span className="fs-20 ff-circularBold text-white">Campaña de leads</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}