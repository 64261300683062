import React from "react";

export default function PaletaCromatica({ proyecto }) {

  return (
    <>
      <div
        className="paleta-titulo d-flex flex-column justify-content-center flex-wrap"
        style={{
          backgroundColor: proyecto.bg_s_paleta,
        }}
      >
        <h1
          className="detalle-proyecto-titulo"
          style={{ color: proyecto.color_s_paleta,fontFamily: `'${proyecto.fuente[0].fontFamily}', sans-serif` }}
        >
          Paleta <br/>
          Cromática
        </h1>
        <span  className="detalle-proyecto-subtitulo"style={{ color: proyecto.color_s_paleta }}>
          Subcategorías y usos
        </span>
      </div>
      <div className="container-fluid">
        <div className="row">
          {proyecto.paleta?.map((colors, i) => {
            return (
              <div
                key={i}
                className="col-sm-12 d-flex flex-column justify-content-center flex-wrap seccion-paleta-color"
                style={{ backgroundColor: `#${colors.HTML}`,paddingLeft: "8%", color: colors.HTML =='FFFFFF' || colors.HTML == 'F4F5F2' || colors.HTML == 'F7F8CA' || colors.HTML == 'E5E4DF' ? `#000000`: '#FFFFFF'}}
              >
                <span>CMYK: {colors.CMYK}</span>
                <span>RGB: {colors.RGB}</span>
                <span>HTML:{` ${colors.HTML}`}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
