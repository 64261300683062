import {
  faArrowUp,
  faArrowDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef } from "react";

export default function ModalSlide({ slide, showModal, setShowModal }) {

  const [switchInfo, setSwitchInfo] = useState(false);

  const slide1Ref = useRef(null);
  const slide2Ref = useRef(null);
  const arrowRef = useRef(null);

  const toggleSlide = () => {
    if (switchInfo) {

      setTimeout(()=>{
        slide1Ref.current.style.opacity = 1;
      },500)
      slide2Ref.current.style.opacity = 0;
      arrowRef.current.style.transform = 'rotate(0)';

    } else {

      slide1Ref.current.style.opacity = 0;
      setTimeout(()=>{
        slide2Ref.current.style.opacity = 1;
      },500)
      arrowRef.current.style.transform = 'rotate(180deg)';

    }
    setSwitchInfo(!switchInfo)
  }

  return (
    <div className={`wrapper ${showModal ? "" : "d-none"}`} key={slide.id}>
      <div
        className={`image-wrapper ${showModal ? "active" : ""}`}
        id="image-wrapper"
      >
        <img src={slide.image} alt="" />
        <div className="image-overlay" id="overlay">
          <div className="container h-100">
            <div className="row h-100 position-relative d-flex align-items-center">

                {/* Primer Slide de info */}
                <div className="col-md-6 col-xl-4 first-slide" ref={slide1Ref}>
                    <div className="title fs-3 text-yellow items-slide-1">
                        .0{slide.id}
                        <br />
                        {slide.name}
                    </div>
                    <div className="mt-3">
                        <h3 className="fs-3 text-yellow fw-bold items-slide-1">
                        Conecta con tus clientes
                        </h3>
                    </div>
                    <p className="text-white mt-5 items-slide-1" dangerouslySetInnerHTML={{__html: slide.text1}}></p>
                </div>


                {/* Segundo slide de info */}
                <div className="second-slide position-absolute row items-slide-2" ref={slide2Ref}>
                    <div className="col-md-6 col-sm-12 col-xl-4 text-white" >
                        <div className="containerParagraphs">
                          <div dangerouslySetInnerHTML={{__html: slide.text2}} className="slideParagraphs"></div>
                        </div>

                        <p className="mt-4">Cliente: {slide.client}</p>
                        <a className="link text-yellow">{slide.link}</a>
                        <br />

                        <a
                        className="align-items-center btn d-flex fw- gap-2 mt-4"
                        href={slide.link}
                        target="_blank"
                        style={{
                            width: "fit-content",
                            padding: "5px 20px",
                            background: "white",
                            fontSize: "17px",
                            borderRadius: "3px",
                            color: "var(--lightBlue-01)",
                        }}
                        >
                            Ver Proyecto
                            <FontAwesomeIcon
                                icon={faArrowUp}
                                style={{ transform: "rotate(90deg)" }}
                            />
                        </a>
                    </div>
                </div>


              <span
                onClick={()=>toggleSlide()}
                className="circle-arrow mt-5"
              >
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="fs-4 text-yellow"
                  ref={arrowRef}
                />
              </span>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className="fs-2 text-yellow position-absolute close-img"
            onClick={() => setShowModal(false)}
          />
        </div>
      </div>
    </div>
  );
}
