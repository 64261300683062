import React from "react";

export default function SeccionFotografia({ proyecto }) {
  const splitTitle = (title) => {
    const words = title.split(" ");
    const firstWord = words.shift();
    const restOfTitle = words.join(" ");

    return (
      <>
        {firstWord}
        <br />
        {restOfTitle}
      </>
    );
  };

  return (
    <>
      <div
        className="paleta-titulo d-flex flex-column justify-content-center flex-wrap"
        style={{
          backgroundColor: proyecto.bg_s_fotografia,
        }}
      >
        <h1
          className="detalle-proyecto-titulo"
          style={{
            color: proyecto.color_s_fotografia,fontFamily: `'${proyecto.fuente[0].fontFamily}', sans-serif`,
          }}
        >
          Fotografía
        </h1>
        <span
          className="detalle-proyecto-subtitulo"
          style={{ color: proyecto.color_s_fotografia }}
        >
          Elección y Usos
        </span>
      </div>
      <div className="bg-light">
        <div className="container-fluid">
          <div className="row">
            {proyecto.galeria?.map((item, index) => (
              item.titulo ?
             (<div
                className="col-md-6"
                key={index}
                style={{ color: `#${proyecto.paleta[0].HTML}` }}
              >                
                  <div className="d-flex flex-column flex-xl-row  align-items-center  align-items-md-start p-md-5 p-3">
                    <img
                      src={item.img}
                      alt="Imagen"
                      className="mr-3 mb-5 mb-md-0 pb-2"
                      style={{ maxHeight: "400px" }}
                    />
                    <div className="ms-3 align-items-start justify-content-center">
                      <h3 className="fs-3 mb-3" style={{ fontSize: "25px" }}>
                        {splitTitle(item.titulo)}
                      </h3>
                      <p className="texto-fotografias">{item.texto}</p>
                    </div>
                  </div>
                  </div>
                ) : (
                  <div className="container text-center col-md-6 ">
                    <img
                      src={item.img}
                      alt="Imagen"
                      className="img-fluid  mb-3  mt-3"
                    />
                  </div>
                )
              
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
