import React from 'react';
import { Routes, Route } from "react-router-dom";

import DesarrolloWeb from './pages/desarrollo-web/DesarrolloWeb';
import Home from './pages/home/Home';
import Singulares from './pages/branding/singulares/Singulares'
import Branding from './pages/branding/Branding'
import Rellax from 'rellax';
import Cookies from './layouts/Cookies/Cookies';
import PrivacyPolicy from './pages/legals/PrivacyPolicy';
import DetalleProyectos from './pages/branding/detalle_proyectos/DetalleProyectos';
import ReactPixel from 'react-facebook-pixel';
import Galery from './components/galery';
import NotFound from './pages/NotFound';
import DesarrolloConversiones from './pages/desarrollo-conversiones/DesarrolloConversiones';
import GraciasTotales from './pages/gracias/GraciasTotales';

const options = {
  autoConfig: true,
  debug: false,
};


function App() {
  ReactPixel.init('1358129458384848', options);

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  const [isTable, setIsTable] = React.useState(window.innerWidth > 768 && window.innerWidth < 1024);


  React.useEffect(() => {
    function handleResize() {
      setIsTable(window.innerWidth > 768 && window.innerWidth < 1024)
      setIsMobile(window.innerWidth < 768);
    }
    if (document.querySelector('.rellax')) {
      new Rellax(".rellax", { // <---- Via class name
        speed: -2,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home isMobile={isMobile} />} />
        <Route path="/desarrollo-web" element={<DesarrolloWeb isMobile={isMobile} isTable={isTable} />} />
        <Route path="/branding" element={<Branding isMobile={isMobile} />} />
        <Route path='/portfolio-web' element={<Galery isMobile={isMobile} firstTitle={"Hacemos monerías, pero no tiramos frutas"} secondTitle={"Más de 80 proyectos web."} />} />
        <Route path="/diseño-y-desarrollo-web" element={<DesarrolloConversiones isMobile={isMobile} isTable={isTable} />} />
        <Route path="/development-webdesign" element={<DesarrolloConversiones isMobile={isMobile} isTable={isTable} />} />
        <Route path='/gracias' element={<GraciasTotales />} />
        {/* <Route path="/branding/singulares" element={<Singulares isMobile={isMobile} />} /> */}
        <Route path='/privacy-policy' element={<PrivacyPolicy isMobile={isMobile} />} />
        <Route path='/branding/:ruta' element={<DetalleProyectos />} />
        {/* 404 Not Found route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Cookies />
    </>
  );
}

export default App;
