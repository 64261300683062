import SectionOne from './section_1/SectionOne'
import SectionTwo from './section_2/SectionTwo'
import SectionThree from './section_3/SectionThree'
import SectionFour from './section_4/SectionFour'
import SectionFourB from './section_4b/SectionFourB'

import SectionFive from './section_5/SectionFive'
import SectionSix from './section_6/SectionSix'
import SectionSeven from './section_7/SectionSeven'
import SectionEight from './section_8/SectionEight'
import SectionNine from './section_9/SectionNine'
import SectionTen from './section_10/SectionTen'
import SectionEleven from './section_11/SectionEleven'
import SectionTwelve from './section_12/SectionTwelve'
import Sectionthirteen from './section_13/Sectionthirteen'
import SectionFourteen from './section_14/SectionFourteen'
import SectionFifteen from './section_15/SectionFifteen'
import Navbar from '../../layouts/Navbar'
import Footer from '../../layouts/Footer'
import MobileNavbar from '../../layouts/MobileNavbar'

import React, {useEffect, useRef} from 'react'

import CustomSlider from './section_2/CustomSlider'
import CustomSliderMobile from './section_2/CustomSliderMobile'
import ContactComponent from '../shared-components/contactComponent/ContactComponent'
import TestimoniosComponent from '../shared-components/testimoniosComponent/Testimonios'

import './desarrolloweb.css'

export default function DesarrolloWeb({isMobile, isTable}){
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);
    const [lottieContact, setLottieContact] =React.useState(false)
    const [lottieChat, setLottieChat] =React.useState(false)
    
    const contactRef = useRef()
    const chatLottieRef= useRef()

    useEffect(() => {
        const handleScroll = (event) => {

            if(chatLottieRef){
                if(chatLottieRef.current.getBoundingClientRect().y<1100){
                    if(!lottieChat)setLottieChat(true)
                }else if(chatLottieRef.current.getBoundingClientRect().y>1300){
                    if(lottieChat)setLottieChat(false)
                } 

                // Pausar mas abjo de la seccion de chat
                if(chatLottieRef.current.getBoundingClientRect().y<-1000){
                    if(lottieChat)setLottieChat(false)
                }
            }
          
          // Detect to play/pause Contact lottie
          if (contactRef){
            if(contactRef.current.getBoundingClientRect().y < 1000){
                if(!lottieContact)setLottieContact(true)
            }
           
            if(contactRef.current.getBoundingClientRect().y >= 1000){
                if(lottieContact)setLottieContact(false)
            }

          }

          

        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [lottieContact,lottieChat]);

    return(
        <>
            <Navbar isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
            <h1 className='position-absolute opacity-0'>Empresa de diseño y desarrollo web en Argentina</h1>
            {isMobile ?
            <>

                {/* Renderiza componentes para mobile */}
                <MobileNavbar  menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
                <SectionOne />
                <CustomSliderMobile />
                <SectionThree />
                <div ref={chatLottieRef}>
                <SectionFour lottieChat={lottieChat}/>
                </div>
                <SectionFourB />
                <SectionFive />
                <SectionSix />
                <SectionSeven />
                <SectionEight />
                <SectionNine />
                <SectionTen />
                <SectionEleven />
                <SectionFourteen />
                <TestimoniosComponent />
                <div ref={contactRef}>
                    <ContactComponent isMobile={isMobile} lottieContact={lottieContact} />
                </div>
                
            </>
                :
            <>
                {/* Renderiza componentes para desktop */}
                <SectionOne />
                <CustomSlider />
                <SectionThree />
                <div ref={chatLottieRef}>
                <SectionFour lottieChat={lottieChat}/>
                </div>
                <SectionFourB />
                <SectionSeven />
                <SectionEight />
                <SectionNine />
                <SectionEleven />
                <SectionTwelve />
                <Sectionthirteen />
                <SectionFourteen />
                <SectionFifteen />
                <TestimoniosComponent />
                <div ref={contactRef}>
                    <ContactComponent isMobile={isMobile} lottieContact={lottieContact} />
                </div>
            </>
        }
            <Footer />
        </>
    )
}