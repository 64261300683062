import Slider from "react-slick";
import './slick.css'
// import './slickTheme.css'
import {PrevArrow, NextArrow} from './arrows'

import React, { useEffect, useState, useRef  } from 'react';
import './styles.css'

export default function CustomSliderMobile(){

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const sliderRef = useRef(null);

    const handleTabClick = (slideIndex) => {
      sliderRef.current.slickGoTo(slideIndex);
    };

      const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setActiveSlideIndex(current),
        appendDots: dots => (
            <ul className="dots-slider-list">
                {dots}
            </ul>
          ),
          customPaging: i => (
            <span className="dot-slider-mobile">

            </span>
          )
      };
      const TABS = [
        { label: 'Página web', slideIndex: 0 },
        { label: 'Landing Page', slideIndex: 1 },
        { label: 'eCommerce', slideIndex: 2 },
        { label: 'Plataforma', slideIndex: 3 },
        { label: 'App', slideIndex: 4 },
      ];
    return(
        <section id="slider-desarrollo-mobile" className="position-relative d-block d-md-none">
        <div className="container">
            <div className="row">
                <div className="col-12 position-relative">
                    <div className="nav w-100 nav-slider">
                        <ul className="overflow-scroll mobile-tabs-list py-2" style={{ whiteSpace: "nowrap" , paddingRight: "3rem"}}>

                            {TABS.map(({ label, slideIndex }) => (
                                <li key={slideIndex} >
                                    <button className={`mobile-tap-slider ${activeSlideIndex==slideIndex ? 'fw-bold fc-yellow' :null}`} onClick={() => handleTabClick(slideIndex)}>{label}</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Slider  ref={sliderRef} {...settings}  >
            <div className="item item-mobile" data-hash="pagina-web">
                <img src="/images/img/mockup/bg-slider-mobile-web.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h4>01</h4>
                            <h3 className="ff-circularBold">Pagina Web</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">Creamos tu <span className="fc-lightBlue">sitio web</span> a tu medida</h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Un sitio intuitivo, rápida y persona</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero digitalizarme</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item item-mobile" data-hash="landing-page">
                <img src="/images/img/mockup/bg-slider-mobile-landing.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h4>02</h4>
                            <h3 className="ff-circularBold">Landing Page</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">Diseñamos un <span className="fc-lightBlue">landing page</span> que enamore</h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Para convertir a tus visitantes, en clientes</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero enamorarme</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item item-mobile" data-hash="ecommerce">
                <img src="/images/img/mockup/bg-slider-mobile-ecommerce.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h4>03</h4>
                            <h3 className="ff-circularBold">Ecommerce</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">Te ayudamos a despegar con el <span className="fc-lightBlue">e-commerce</span> que necesitas</h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Llega a nuevos mercados y genera nuevas estrategias</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero evolucionar</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item item-mobile" data-hash="plataforma">
                <img src="/images/img/mockup/bg-slider-mobile-plataformas.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h4>04</h4>
                            <h3 className="ff-circularBold">Plataforma</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">Desarrollamos tu  <span className="fc-lightBlue">plataforma</span> para que no dejes de crecer</h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Automatización e integración de procesos</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero crecer</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item item-mobile" data-hash="app">
                <img src="/images/img/mockup/bg-slider-mobile-app.jpg" className="bg" />
                <div className="container d-flex   h-100">
                    <div className="row text-sliders">
                        <div className="col-12 d-flex flex-column align-self-center mb-35 justify-content-center h-100">
                            <h4>05</h4>
                            <h3 className="ff-circularBold">App</h3>
                            <div className="d-flex align-items-baseline mb-3 mt-3">
                                <img src="/images/img/img-arrow-yellow.svg" className="img me-2" />
                                <h4 className="ff-circularBold mb-0">
                                Creamos tu <span className="fc-lightBlue">APP</span> para que la distancia no sea un
                                    problema.
                                </h4>
                            </div>
                            <h5 className="fc-yellow mb-4">Automatización e integración de procesos</h5>
                            <a href="#form" className="btn btn-outline-primary">Quiero acercarme</a>
                        </div>
                    </div>
                </div>
            </div>
        </Slider>
    </section>
    )
}