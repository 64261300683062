import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function SeccionTipografia({ proyecto }) {
  return (
    <>
      <div
        className="paleta-titulo d-flex flex-column justify-content-center flex-wrap"
        style={{
          backgroundColor: proyecto.bg_s_tipografia,
        }}
      >
        <h1 className="detalle-proyecto-titulo"
          style={{ fontFamily: `'${proyecto.fuente[0].fontFamily}', sans-serif` }}>
          Tipografía
        </h1>
        <span
          className="detalle-proyecto-subtitulo"
          style={{ color: proyecto.color_s_tipografia }}
        >
          Elección y Usos
        </span>
      </div>
      <div
        className="bg-light"
        style={{
          color:  proyecto.color_text_tipografia,
          paddingLeft: "8%",
          paddingBottom: "3%",
        }}
      >
        {proyecto.fuente?.map((fuentes, i) => {
          return (
            <div key={i}>
              <h1
                className="pt-5 titulo-seccion-fuente"
                style={{ fontFamily: `'${fuentes.fontFamily}',  Arial, Helvetica, sans-serif`, fontWeight: 800 }}
              >
                {fuentes.fontFamily}
              </h1>
              <h1
                style={{
                  fontFamily: `'${fuentes.fontFamily}',  Arial, Helvetica, sans-serif`,
                }}
              >
                Aa
              </h1>
              <ul className="ul-tipografia">
                {fuentes.ejemplosFuentes?.map((fuente, i) => {
                  return (
                    <li
                      style={{
                        fontFamily: `'${fuente.nombreFuente}',  Arial, Helvetica, sans-serif`,
                      }}
                    >
                      {fuente.tipoFuente}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
}
