import circulo from './../../../assets/img/slider-desarrollo/img-circulo.svg'
import img01 from './../../../assets/img/slider-desarrollo/img-01.svg'
import img02 from './../../../assets/img/slider-desarrollo/img-02.svg'
import img03 from './../../../assets/img/slider-desarrollo/img-03.svg'
import img04 from './../../../assets/img/slider-desarrollo/img-04.svg'
import img05 from './../../../assets/img/slider-desarrollo/img-05.svg'
import imgTest from './../../../assets/img/slider-desarrollo/img-test.svg'
import imgFinal from './../../../assets/img/slider-desarrollo/img-final.png'
import imgCirculo from './../../../assets/img/slider-desarrollo/img-circulo-fin.svg'

import arrowForm from './../../../assets/img/icon-arrow-form.svg'
import marcadorPortafolio from './../../../assets/img/img-marcador-portfolio.svg'
import paso1 from './../../../assets/img/paso-1.svg'
import paso2 from './../../../assets/img/paso-2.svg'
import paso3 from './../../../assets/img/paso-3.svg'
import paso3b from './../../../assets/img/paso-3b.svg'
import iconAngle from './../../../assets/img/icon-angle-rigth.svg'

import React, { useState, useRef } from 'react';

export default function SectionTrhee(){

    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(null);
    const sliderRef = useRef(null);
    
    function handleMouseDown(e) {
        setIsDown(true);
        // sliderRef.current.classList.add('active');
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    }
    
    function handleMouseLeave() {
        setIsDown(false);
        // sliderRef.current.classList.remove('active');
    }
    
    function handleMouseUp() {
        setIsDown(false);
        // sliderRef.current.classList.remove('active');
    }
    
    function handleMouseMove(e) {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 1.5; //scroll-fast
        sliderRef.current.scrollLeft = scrollLeft - walk;
    }
    
    
    function handleButtonClick() {
        sliderRef.current.scrollLeft += 350;
    }

    return(
        <section id="desarrollo-mapa" class="pb-100 pt-100">
        <div class="branding d-flex align-items-center mt75 mb75">
            <div class="container">   
                <div class="row">
                    <div class="col-md-1 d-flex flex-column flex-wrap align-content-start justify-content-start">
                        <img src={marcadorPortafolio} alt="" />
                    </div>
                    <div class="col-md-6 d-flex flex-column flex-wrap align-content-start justify-content-start">
                        <h2 class="fc-lightBlue" style={{fontFamily:'Circular Black'}}>Proceso de<br />Trabajo</h2>
                    </div>
                </div>
            </div>
        </div>
        
        <ul id="slider_dessarrollo" ref={sliderRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} style={{ scrollBehavior: 'smooth' }}>
            <div class="slider d-flex justify-content-around">
                <span></span>
                <li class="d-flex position-relative"><img class="img-fluid" src={circulo} alt="" /></li>
                
                <li class="box">
                    <div class="d-flex flex-column align-items-center justify-content-between py-5">
                        <h3 class="fc-darkBlue-01 text-center">Brief</h3>
                        <img src={paso1} class="img-front" width="80%" />
                        <h4 class="fc-darkBlue-01"><img src={arrowForm} alt="" /> El cliente nos brinda información.</h4>   
                    </div>
                    <h5 class="text-white position-absolute d-sm-block d-md-none mobile-text" > El cliente nos brinda información.</h5>
                </li>
                <li class="d-flex position-relative"><img class="img-fluid angle-right" src={iconAngle} alt="" onClick={handleButtonClick} /></li>

                <li class="box">
                    <div class="d-flex flex-column align-items-center justify-content-between py-5">
                        <h3 class="fc-darkBlue-01 text-center">Referencias estéticas</h3>
                        <img src={img01} class="img-number" />
                        <img src={paso2} class="img-front" width="80%" />
                        <h4 class="fc-darkBlue-01" style={{paddingTop:'32px'}}><img src={arrowForm} alt="" /> Proponemos caminos gráficos a seguir de acuerdo al brief.</h4>
                    </div>
                    <h5 class="text-white position-absolute d-sm-block d-md-none mobile-text" > Proponemos caminos gráficos a seguir de acuerdo al brief.</h5>
                </li>
                <li class="d-flex position-relative"><img class="img-fluid angle-right" src={iconAngle} onClick={handleButtonClick} alt="" /></li>

                <li class="box">
                    <div class="d-flex flex-column align-items-center justify-content-between py-5">
                        <h3 class="fc-darkBlue-01 text-center">Propuesta de diseño</h3>
                        <img src={img02} class="img-number" />
                        <img src={paso3} class="img-front" width="80%" />
                        <h4 class="fc-darkBlue-01"><img src={arrowForm} alt="" /> Trabajamos en dos propuestas de diseño.</h4>
                    </div>
                    <h5 class="text-white position-absolute d-sm-block d-md-none mobile-text" > Trabajamos en dos propuestas de diseño.</h5>
                </li>
                <li class="d-flex position-relative"><img class="img-fluid angle-right" src={iconAngle} onClick={handleButtonClick} alt="" /></li>

                <li class="box">
                    <div class="d-flex flex-column align-items-center justify-content-between py-5">
                        <h3 class="fc-darkBlue-01 text-center">Elección</h3>
                        <img src={img03} class="img-number"/>
                        <img src={paso3b} class="img-front" width="80%"/>
                        <h4 class="fc-darkBlue-01"><img src={arrowForm} alt=""/> El cliente elige una opción y puede hacer una instancia de cambios.</h4>
                    </div>
                    <h5 class="text-white position-absolute d-sm-block d-md-none mobile-text" > El cliente elige una opción y puede hacer una instancia de cambios.</h5>
                </li>
                <li class="d-flex position-relative"><img class="img-fluid angle-right" src={iconAngle} onClick={handleButtonClick} alt=""/></li>
                
                <li class="box">
                    <div class="d-flex flex-column align-items-center justify-content-between py-5">
                        <h3 class="fc-darkBlue-01 text-center">Trabajo final</h3>
                        <img src={img04} class="img-number"/>
                        <img src={imgTest} class="img-front" width="80%" />
                        <h4 class="fc-darkBlue-01"><img src={arrowForm} alt=""/> Entregamos manual de marca completo y todos los archivos.</h4>
                    </div>
                    <h5 class="text-white position-absolute d-sm-block d-md-none mobile-text" >  Entregamos manual de marca completo y todos los archivos.</h5>
                </li>
                <li class="d-flex position-relative"><img class="img-fluid angle-right" src={iconAngle} onClick={handleButtonClick} alt=""/></li>

                <li class="box">
                    <div class="d-flex flex-column align-items-center justify-content-between py-5">
                        <h3 class="fc-darkBlue-01 text-center">Final</h3>
                        <img src={img05} class="img-number"/>
                        <img src={imgFinal} class="img-front" width="80%"/>
                        <h4 class="fc-darkBlue-01"><img src={arrowForm} alt=""/> Terminamos nuestra labor cuando empiezas a ver tus objetivos realizados.</h4>
                    </div>
                    <h5 class="text-white position-absolute d-sm-block d-md-none mobile-text" > Terminamos nuestra labor cuando empiezas a ver tus objetivos realizados.</h5>
                </li>
                <li class="d-flex position-relative"><img class="img-fluid" src={imgCirculo} alt=""/></li>
            </div>
        </ul>
    </section>
    )
}