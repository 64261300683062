import React,{useEffect} from "react";
import SeccionSlider from "./SeccionSlider";
import proyectos from "../../../assets/proyectos";
import PaletaCromatica from "./PaletaCromatica";
import SeccionTipografia from "./SeccionTipografia";
import SeccionFotografia from "./SeccionFotografia";
import SeccionPiezasGraficas from "./SeccionPiezasGraficas";
import SeccionExtensionesVisuales from "./SeccionExtensionesVisuales";
import { useParams } from 'react-router-dom';


export default function DetalleProyectos() {
  const { ruta } = useParams();

  const proyecto = proyectos.find((objeto) => objeto.url === ruta);
  useEffect(() => {
    window.scrollTo(0, 0); // Ajusta la posición de desplazamiento a (0, 0) para posicionar la ventana en la parte superior
  }, []);
 
  return (
    <section style={{overflowX:'hidden'}}>
      <SeccionSlider images={proyecto.slider} bg={proyecto.bg_s_paleta} fill={proyecto.fill} />
      <PaletaCromatica proyecto={proyecto} />
      <SeccionTipografia proyecto={proyecto}/>
      <SeccionFotografia proyecto={proyecto}/>
      <SeccionExtensionesVisuales proyecto={proyecto}/>
      <SeccionPiezasGraficas proyecto={proyecto} />
    </section>
  );
}
