export const es = {
    // Your Spanish translations
    nav:{
        contact: 'Contactanos'
    },
    conversions:{
        headerTitle: 'Empresa de diseño y desarrollo web en Argentina'
    },
    slidersServices: {
        web:{
            name:"Página Web",
            title: 'Creamos tu Sitio web a tu medida',
            paragraph: 'Un sitio intuitivo, rápido y personal',
            button: 'Quiero digitalizarme'
        },
        eCommerce:{
            name:"Ecommerce",
            title: 'Te ayudamos a despegar con el e-Commerce que necesitas',
            paragraph: 'Te ayudamos a despegar con el e-Commerce que necesitas',
            button: 'Quiero evolucionar'
        },
        platform:{
            name:"Plataforma",
            title: 'Desarrollamos tu plataforma para que no dejes de crecer',
            paragraph: 'Automatización e integración de procesos',
            button: 'Quiero crecer'
        },
        app:{
            name:"App",
            title: 'Creamos tu APP para que la distancia no sea un problema',
            paragraph: 'Vende más, con eficiencia y rapidez',
            button: 'Consultar'
        }
    },
    partner:{
        weAre: "SOMOS TU",
        partner: "PARTNER",
        manada: "MANADA",
        trustly: "DE CONFIANZA",
        text: "Colaboramos con <strong>agencias y consultoras</strong>, para que amplíen su equipo y capacidades, con un seguimiento personalizado y horizontal, con un costo adaptado a tus posibilidades.",
        agencias: "Agencias y Consultoras",
        button: "Seamos Partners",
        textMobile: "Tenemos un proceso de trabajo especial para"
    },
    technology: 'Tecnologías',
    technologyClass: 'tecnologia',
    videoPosicionamiento: '6ftn7k9iup',
    videoPartners: '3doj7teg2w',
    contactForm:{
        deskTitle: 'Dejanos tu mensaje o inquietud',
        ready: '¿Listo para evolucionar en la jungla digital?',
        weRead:'(Contactanos, leemos atentamente, esto no es un bot)',
        weRead1:'(Leemos atentamente, esto no es un bot)',
        nameAndLast: 'Nombre y Apellido',
        company: 'Empresa',
        email: 'Email',
        phone: 'Teléfono',
        position: 'Puesto',
        message: 'Dejanos tu consulta...',
        required_email: '* Campo obligatorio y debe ser un mail válido',
        required: '* Campo obligatorio',
        success: '¡Éxito!',
        send: 'Enviar',
        weContact: 'Nos pondremos en contacto en breve para que seas parte de esta ',
        evolve: 'evolución digital',
        help: '¿Cómo podemos ayudarte?',
        option1: 'Quiero que mi página web se destaque',
        option2: 'Deseo actualizar mi marca',
        option3: 'Quiero aumentar mi exposición en el mercado',
        option4: 'Tengo una idea digital que quiero realizar',
        leaveMessage: 'Dejanos tu mensaje o inquietud',
    }
  }