import { useEffect, useRef, useState } from "react";
import AOS from "aos";
import Shuffle from "shufflejs";
import { Container, Row } from "react-bootstrap";
import FilterDropdown from "../filterDropdown";
import { sortByPriority, sortNewer, sortOldest } from "../sortFunctions";
import { proyectJson, allCategories } from "../../../utils/galery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./galeryDesktop.css";


import LinearWithValueLabel from "../../../atoms/LinearProgress";
function DesktopGalery({ firstTitle, secondTitle }) {
  const [proyects, setProyects] = useState([]);
  const [allProjects, setAllProyects] = useState([]);
  const [categories, setCategories] = useState([""]);
  const [loading, setLoading] = useState(true);
  const [activeFilter, setActiveFilters] = useState([]);
  const [shuffleRef, setShuffleRef] = useState();
  const [showMore, setShowMore] = useState(1);
  const [showFilterNav, setShowFilterNav] = useState(false);
  const [inLast, setInLast] = useState(false);
  const [flag, setFlag] = useState(false);
  const [progress, setProgress] = useState(10);

  const [arrayLoaded, setArray] = useState([])

  const lastImage = useRef(null);
  const loadAmount = useRef(9);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('./projects.json');
        const jsonData = await response.json();
        setProyects(jsonData);

        setFlag((flag) => !flag);
        if (allCategories) setCategories(allCategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const handleLoading = (n) => {
    // checkeando los primeros 10 elementos cargados

    if (n <= 9 && arrayLoaded.valueOf().length < 10) {
      setArray(oldArray => [...oldArray, n]);

    }
    if (arrayLoaded.valueOf().length >= 10) {
    }

  };

  useEffect(() => {
    const cacheImages = localStorage.getItem('chimpaPortfolioImages') 

    const timer = setInterval(() => {
      // Pause the timer when progress reaches 50%
      if (progress === 50) {
        localStorage.setItem('chimpaPortfolioImages',true)
        clearInterval(timer);
        return;
      }

      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));

      // Check array length and resume timer when >= 10
      if ((arrayLoaded.length >= 10 || cacheImages) && progress !== 100) {
        timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
          localStorage.setItem('chimpaPortfolioImages',true)
        }, 800);
      }

      // Set loading to false when array length >= 10 and progress is 100
      if ((arrayLoaded.length >= 10 || cacheImages) && progress === 100) {
        setLoading(false);
      }
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [arrayLoaded, progress]);

  useEffect(() => {


    if (!loading) {
      AOS.init({ once: true });
      const shuffle = new Shuffle(document.querySelector(".shuffle-wrapper"), {
        itemSelector: ".shuffle-item",
        buffer: 1,
      });
      setShuffleRef(shuffle);
    }
  }, [loading, flag]);

  useEffect(() => {

    if (shuffleRef) shuffleRef.layout();
    if (
      lastImage.current !== null &&
      !lastImage.current.classList.contains("no-load") &&
      parseInt(lastImage.current.dataset.position) === proyects.length - 1
    ) {
      setInLast(true);
    }
  }, [showMore, activeFilter]);

  useEffect(() => {
    setTimeout(() => {
      if (shuffleRef) shuffleRef.layout();
    }, 1500);
    if (shuffleRef) shuffleRef.resetItems();
  }, [proyects]);

  const handleChange = (e) => {
    const input = e.currentTarget;
    const filters = activeFilter;
    if (input.checked) {
      filters.push(input.value);
      setShowMore(999);
    } else {
      filters.splice(filters.indexOf(input.value), 1);
      if (filters.length !== 0) {
        setShowMore(999);
      } else {
        setShowMore(1);
        setInLast(false);
      }
    }
    setActiveFilters(filters);
    shuffleRef.filter(filters);
  };

  const handleSort = (e) => {
    let value = e.target.value;
    shuffleRef.remove(proyects);
    if (value === "relevant") setProyects(sortByPriority(proyects));
    if (value === "older") setProyects(sortOldest(proyects));
    if (value === "newer") setProyects(sortNewer(proyects));
    setShowMore(1);
    setFlag((flag) => !flag);
  };

  const contentStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };




  return (
    <Container className="galery-section">
      <div className="mb-5">
        <div className="yellow-separator mb-4"></div>
        <h4 className="mb-1 ff-circularBold">{firstTitle}</h4>
        <h4 className="fc-lightBlue mb-1 ff-circularBlack">{secondTitle}</h4>
      </div>
      {loading && (
        <div className="d-flex flex-column align-items-center">
          <p className="text-white py-2 fs-5">Cargando más de 80 proyectos..</p>
          <div className="w-50" style={{minWidth:'300px'}}>
            <LinearWithValueLabel progress={progress} />
          </div>
        </div>
      )}
      <Row className={`galery ${loading ? "no-visible" : ""}`}>
        <div className="col-12 d-flex justify-content-end">
          <select className="form-select order-by" onChange={handleSort}>
            <option value="relevant">Ordenar por relevancia</option>
            <option value="newer">Ordenar por más reciente</option>
            <option value="older">Ordenar por más antiguo</option>
          </select>
        </div>
        <div className={`animate-width ${showFilterNav ? "col-3" : "col-1"} `}>
          <FilterDropdown
            categories={categories}
            handleChange={handleChange}
            show={showFilterNav}
            setShow={setShowFilterNav}
          />
        </div>
        <div className={`animate-width ${showFilterNav ? "col-9" : "col-11"}`}>
          <div className="row shuffle-wrapper">
            {proyects[0] &&
              proyects.map((proyect, i) => (
                <div
                  ref={lastImage}
                  key={i + new Date().getTime}
                  data-position={i}
                  className={`shuffle-item ${activeFilter.length > 0
                    ? "col-lg-4 col-6 mb-4"
                    : showMore * loadAmount.current >= i
                      ? "col-lg-4 col-6 mb-4"
                      : "no-load"
                    }`}
                  data-priority={proyect.PRIORIDAD}
                  data-created={proyect.AÑO}
                  data-groups={proyect.GROUPS}
                >
                  <div className="position-relative inner-box"
                    style={{
                      position: 'relative',
                      width: '100%',
                      paddingBottom: `${1 / (proyect.imgSize.width / proyect.imgSize.height) * 100}%`,// Calculate the padding-top percentage based on the aspect ratio
                    }}>
                    <div className="image" style={contentStyle}>
                      <LazyLoadImage
                        src={`images/portfolioChimpa/${proyect.IMG_SRC}`}
                        className="img-fluid d-block img-portfolio"
                        alt={proyect.PROYECTO}
                        onLoad={() => handleLoading(i)}
                        data-position={i}
                        visibleByDefault={true}
                      />

                      {proyect.IMG_COUNTRY ? (
                        <img
                          src={`images/flags/${proyect.IMG_COUNTRY}`}
                          style={{ width: "auto", background: "transparent" }}
                          className="card-flag"
                          alt="Flag"
                        />
                      ) : (
                        <></>
                      )}
                      <div className="overlay-box">
                        {proyect.LINK === "No funciona" ||
                          proyect.LINK === "" ? (
                          <></>
                        ) : (
                          <a
                            className="portfolio-image popup-gallery"
                            target="_blank"
                            href={proyect.LINK}
                          >
                            <i className="bi bi-arrow-up-right"></i>
                          </a>
                        )}

                        <div className="overlay-inner">
                          <div className="overlay-content">
                            <p className="ff-circularLight card-title">
                              {proyect.TITULO}
                            </p>
                            <div className="title-and-year">
                              <h5 className="ff-circularBold fc-lightBlue card-title-proyect">
                                {proyect.PROYECTO}
                              </h5>
                              <p className="ff-circularLight card-year">
                                {proyect.AÑO}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="col-12 d-flex justify-content-center">
            {activeFilter.length > 0 || inLast ? (
              <></>
            ) : (
              <button
                className="see-more"
                onClick={() => setShowMore(showMore + 1)}
              >
                Ver Más
              </button>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
}
export default DesktopGalery;
