import {
  AltaCuchaPortada,
  AltaCuchaPortadaMobile,
  AnaliticaPortada,
  AnaliticaPortadaMobile,
  Antes,
  BgPortfolioBranding,
  BgPortfolioBrandingMobile,
  BgPortfolioMkt,
  BgPortfolioMktMobile,
  BultakoPortada,
  BultakoPortadaMobile,
  BultakoMobile,
  DelGredalPortda,
  DelGredalPortdaMobile,
  Despues,
  EnmascararGrupo235Mobile,
  GusEspadaPortada,
  GusEspadaPortadaMobile,
  LinetecPortada,
  LinetecPortadaMobile,
  PapelesPortada,
  RioSegundoPortada,
  RioSegundoPortadaMobile,
  SingularesPortada,
} from "./../../../assets/img/branding/index";
import componente55 from "./../../../assets/img/Componente 55.png";
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";

export default function SectionTwo() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsVisible(true);
    }, 600);

    return () => {
      clearTimeout(delay);
    };
  }, []);
  return (
    <section className="d-none d-sm-block">
      <div className="container">
        <div className="row mb-4 marginRowBrandingTablet">
          <div className="col-md-8 d-flex flex-column justify-content-center">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible &&(
                  <div className="z-index2 position-absolute w-100 bottom30 px-5 miElemento">
                  <p className="font-circularBlack fw-bold fs-4 ">Branding</p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Singulares</span>
                    <span className=" ">
                      <Link
                        to="/branding/singulares"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}

                 
                  <img loading="lazy" src={SingularesPortada} width="100%" />
                  
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5 miElemento">
                  <p className="font-circularBlack fw-bold fs-4">Branding</p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Del Gredal</span>
                    <span className=" ">
                      <Link
                        to="/branding/del-gredal"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={DelGredalPortda} width="100%" />
                  
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 min-vh-44 marginRowBrandingTablet">
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">
                    Identidad Visual
                  </p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Alta Cucha</span>
                    <span className=" ">
                      <Link
                        to="/branding/alta-cucha"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={AltaCuchaPortada} width="100%" />
                  
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
               {isVisible && (
                <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">Branding</p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Linetec</span>
                    <span className=" ">
                      <Link
                        to="/branding/linetec"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={LinetecPortada} width="100%" />
                  
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">
                    Identidad Visual
                  </p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Analitica</span>
                    <span className=" ">
                      <Link
                        to="/branding/analitica427"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={AnaliticaPortada} width="100%" />
                  
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 min-vh-44 marginRowBrandingTablet">
          <div className="col-md-8 d-flex flex-column justify-content-center">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible &&(
                <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">
                    Identidad Visual
                  </p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">
                      Municipalidad Rio II
                    </span>
                    <span className="">
                      <Link
                        to="/branding/municipalidad-rio-II"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={RioSegundoPortada} width="100%" />
                  
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible &&(
                <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">
                    Identidad Visual
                  </p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Gus Espada</span>
                    <span className=" ">
                      <Link
                        to="/branding/gus-espada"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={GusEspadaPortada} width="100%" />
                  
              </div>
            </div>
          </div>
        </div>
        <div className="row marginRowBrandingTablet">
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">
                    Identidad Visual
                  </p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Bultako</span>
                    <span className=" ">
                      <Link
                         to="/branding/bultako"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={BultakoPortada} width="100%" />
                  
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">Key Visual</p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Papeles.net</span>
                    <span className=" ">
                      <Link
                        to="/branding/papeles-net"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={PapelesPortada} width="100%" />
                  
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ width: "fit-content", position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                <div className="z-index2 position-absolute w-100 bottom30 px-5">
                  <p className="font-circularBlack fw-bold fs-4">
                    Identidad Visual
                  </p>
                  <div className="d-flex justify-content-between">
                    <span className="ff-circularLight">Delivery Wap</span>
                    <span className=" ">
                      <Link
                        to="/branding/delivery-wap"
                        className="link-light border-bottom arrowActive"
                      >
                        Ver más{" "}
                        <svg
                          id="Grupo_2257"
                          className="mt--5 arrowTransition"
                          data-name="Grupo 2257"
                          xmlns="http://www.w3.org/2000/svg"
                          xlinkHref="http://www.w3.org/1999/xlink"
                          width="25"
                          height="12"
                          viewBox="0 0 29.967 14.518"
                        >
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                id="Rectángulo_1972"
                                data-name="Rectángulo 1972"
                                width="29.967"
                                height="14.518"
                                fill="#fff"
                              />
                            </clipPath>
                          </defs>
                          <g
                            id="Grupo_2255"
                            data-name="Grupo 2255"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Trazado_9345"
                              data-name="Trazado 9345"
                              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
                )}
                 
                  <img loading="lazy" src={componente55} width="100%" />
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
