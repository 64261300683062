import Lottie from "lottie-react";
import bgJunglaDesk from './../assets/img/lotties/bgJunglaDesk.json'
import bgJunglaMobile from './../assets/img/lotties/bgJunglaMobile.json'
import './Navbar.styles.css'
import chimpaLogo from './../assets/img/chimpance-logo.svg'
import isoImg from './../assets/img/iso.svg'
import React, {useRef} from 'react'
import flagArgMobile from './../assets/img/flag-arg-mobile.svg'
import flagUsa from './../assets/img/flag-usa-mobile.svg'
import { numberPhone } from "../utils/configChimpa";

export default function Navbar({isMobile, menuIsOpen, setMenuIsOpen}){

    
    const [isScrolled, setIsScrolled] = React.useState(false);
    const lottieRef = useRef();
    const lottieMobileRef = useRef(null);

    React.useEffect(() => {
      function handleScroll() {
        const currentScrollPosition = window.pageYOffset;
        if (currentScrollPosition > 50) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    React.useEffect(()=>{
        if(menuIsOpen){
            if(lottieRef.current || lottieMobileRef.current){
                if(lottieRef.current){
                    lottieRef.current.play()
                }
                if(lottieMobileRef.current){
                    lottieMobileRef.current.play() 
                }
            
            }
        }else{
            if(lottieRef.current || lottieMobileRef.current){
                if(lottieRef.current){
                    lottieRef.current.pause()
                }
                if(lottieMobileRef.current){
                    lottieMobileRef.current.pause() 
                }
              
            }
        }
    },[menuIsOpen])

    const showMenu = () => {
        setMenuIsOpen(!menuIsOpen)
        setIsScrolled(!isScrolled)
    }

    // Travel to Contact and then Close the menu
    const contactShowMenu = () => {
        setTimeout(showMenu, 900)
    }

    return(
        <>
            <nav id="navbar" className={`navbar custom-nav ${isScrolled && !menuIsOpen ? 'bg-scroll-navbar' : ''}`}>
                <div className="container position-relative">
                    <div className="d-none d-md-block">
                        <a className="navbar-brand" href="/">
                            <img loading="lazy" src={chimpaLogo} alt="Chimpance" />
                        </a>
                    </div>
                    <div className="col-12 d-flex justify-content-center d-md-none">
                        <a className="navbar-brand" href="/">
                            <img loading="lazy"  src={isoImg} alt="Chimpance" />
                        </a>
                    </div>
                    <div className="d-flex align-items-center">
                        <a id="contact_nav" href={"https://web.whatsapp.com/send?phone=+"+ numberPhone + "&amp;text=Buenos%20días,%20quiero%20mas%20info%20sobre%20desarrollo%20web"} target="_blank"  className={`${menuIsOpen ? 'd-md-none' : ''} btn btn-white d-none d-md-block FB-mkt`} >Contactanos</a>
                        <div className="d-none d-md-block">
                            
                            <div className={`hamburger ${menuIsOpen ? 'checked' : ''}`} id="hamburger" onClick={()=>showMenu()} > <div></div> </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="menu-wrap nav-mobile">
                <div className={`menu ${menuIsOpen ? 'checked' : ''}`} id="menu">
                    <div>
                        {!isMobile ?
                        (
                            <>
                            {/* <div id="bg-video" className="d-block " style={{position: 'absolute'}}>
                            <Lottie   loop={true}  mode={"normal"} lottieRef={lottieMobileRef} animationData={bgJunglaMobile} background="transparent" className="bg-lottie-jungle" />
                        </div> */}
                        <div id="bg-video" className="d-none d-md-block" style={{position: 'absolute'}}>
                            <Lottie id="nav-deskLottie" rendererSettings={{preserveAspectRatio:"xMidYMid slice"}}  lottieRef={lottieRef} loop={true}  mode={"normal"} animationData={bgJunglaDesk} background="transparent" className="bg-lottie-jungle" />
                        </div>
                            </>
                        ):
                        (<div id="bg-video" className="d-block d-md-none" style={{position: 'absolute'}}>
                            <Lottie   loop={true} rendererSettings={{preserveAspectRatio:"xMidYMid slice"}} mode={"normal"} lottieRef={lottieMobileRef} animationData={bgJunglaMobile} background="transparent" className="bg-lottie-jungle" />
                        </div>)
                        }
                        <div>
                            <ul className="p-0">
                                <li><a className="ff-circularBold" href="/desarrollo-web" id="link">Web UX UI</a></li>
                                <li><a className="ff-circularBold" href="/portfolio-web" id="link">Proyectos web</a></li>
                                <li><a className="ff-circularBold" href="/branding" id="link">Branding</a></li>
                                <li><a className="ff-circularBold" href="#form" id="link" onClick={()=>contactShowMenu()}>Contacto</a></li>
                                <li className="nav-item dropdown d-none ">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Español <img src={flagArgMobile} />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <a className="dropdown-item d-flex justify-content-between" href="#">
                                                Ingles <img src={flagUsa} />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}