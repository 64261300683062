import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import toTop from "../../../assets/img/DetalleProyectos/SingularesDetalle/icon-to-top.png";
import { animateScroll as scroll } from "react-scroll";

export default function SeccionPiezasGraficas({ proyecto }) {
  function scrollToTop() {
    scroll.scrollToTop({
      duration: 300, // Duración del desplazamiento en milisegundos
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <section className="bg-light">
      <div
        className="paleta-titulo d-flex flex-column justify-content-center flex-wrap"
        style={{
          backgroundColor: proyecto.bg_s_piezasGraficas,
        }}
      >
        <h1
          className="detalle-proyecto-titulo"
          style={{
            fontFamily: `'${proyecto.fuente[0].fontFamily}', sans-serif`,
            color: proyecto.color_s_piezasGraficas,
          }}
        >
          Piezas <br />
          Gráficas
        </h1>
      </div>

      <div className="container-fluid p-0">
        <div className="row justify-content-center ">
          {proyecto.imgPortada ? (
            <div className="col-12 ">
              {proyecto.piezasGraficas?.map((imagen, index) => {
                return (
                  <div key={index} className="text-center">
                    <img src={imagen} alt="Imagen" className="img-fluid" />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="col-12 ">
              {proyecto.piezasGraficas?.map((imagen, index) => {
                return (
                  <div key={index} className="text-center">
                    <div className="position-relative">
                      <img
                        src={toTop}
                        alt=""
                        className="position-absolute to-top start-50 "
                        onClick={scrollToTop}
                      />
                    </div>
                    <img src={imagen} alt="Imagen" className="img-fluid" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {proyecto.imgPortada && (
          <div className="row justify-content-center p-0 ">
            <div className="position-relative">
              <img
                src={toTop}
                alt=""
                className="position-absolute to-top start-50 "
                onClick={scrollToTop}
              />
            </div>
            <img src={proyecto.imgPortada} alt="" />
          </div>
        )}
      </div>
    </section>
  );
}
