import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { PrevArrow, NextArrow } from "../../desarrollo-web/section_2/arrows";
import { Link } from "react-router-dom";

const SeccionSlider = ({ images, bg , fill }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const carouselRef = useRef(null);

  const handleTabClick = (slideIndex) => {
    carouselRef.current.slickGoTo(slideIndex);
  };

  console.log(typeof images, "IMAGEEEEEEEEEEEEEEEEEEEEEEES");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dotsClass: "detail-proyect-dots",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
    afterChange: (current) => setActiveSlideIndex(current),
    appendDots: (dots) => <ul className="dots-slider-list">{dots}</ul>,
    customPaging: (i) => <span className="proyect-dot-slider-mobile"></span>,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const imageContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxHeight: "85vh",
  };

  const imageStyle = {
    width: "100%",
    maxHeight: "85vh",
    objectFit: "cover",
  };

  return (
    <div className="position-relative">
      <Link
        to="/branding"
        className="link-light border-bottom arrowVolverActive"
      >
        <svg
          id="Grupo_2257"
          className="mt--5 arrowVolver"
          data-name="Grupo 2257"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
          width="25"
          height="12"
          viewBox="0 0 29.967 14.518"
        >
          <defs>
            <clipPath id="clipPath">
              <rect
                id="Rectángulo_1972"
                data-name="Rectángulo 1972"
                width="29.967"
                height="14.518"
                fill="#fff"
              />
            </clipPath>
          </defs>
          <g id="Grupo_2255" data-name="Grupo 2255" clipPath="url(#clipPath)">
            <path
              id="Trazado_9345"
              data-name="Trazado 9345"
              d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
              transform="translate(0 0)"
              fill={fill}
            />
          </g>
        </svg>
        <span style={{ color: fill }} >Volver</span>
      </Link>
      {typeof images == "string" ? (
        <div className="container-fluid p-0">
          <img
            src={images}
            alt={images}
            className=" image-fluid"
            style={imageStyle}
          />
        </div>
      ) : (
        <div
          className="bg-ligth p-0 m-0"
          style={{ width: "100%", maxHeight: "85vh", backgroundColor: bg }}
        >
          <Slider {...settings}>
            {images?.map((img, i) => {
              return (
                <div
                  key={i}
                  className="slider-image-container container-fluid bg-light p-0 m-0"
                  style={imageContainerStyle}
                >
                  <img
                    src={img}
                    alt={img + i}
                    style={imageStyle}
                    className="p-0 m-0"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default SeccionSlider;
