import bgPosicionamiento from './../../../assets/img/bg-posicionamiento.png'
import laptopImage from './../../../assets/img/mock-laptop.svg'
import arrowYellow from './../../../assets/img/arrow-yellow.svg'
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WistiaProvider, WistiaPlayer } from '@wistia/react-embeds';
import './SectionThree__styles.css'
export default function SectionThree(){
    const imageJungleRef= useRef()
    const { t } = useTranslation()

    const handlePlayEvent = () => {
        imageJungleRef.current.style.opacity= 0
      };
   

        const targetClass ='w-big-play-button'
        
        useEffect(() => {
           
            let intervalId;
        
            const checkElement = () => {
              const element = document.querySelector(`.${targetClass}`);
              if (element) {
                // Element with the specific class is found, perform your action here
                element.addEventListener("click", handlePlayEvent)
                clearInterval(intervalId);
              }
            };
        
            intervalId = setInterval(checkElement, 700); // Adjust the interval duration as needed
        
            // Cleanup function to clear the interval when the component unmounts
            return () => {
              clearInterval(intervalId);
            };
          }, []);
     

         
    return(
        <section id="posicionamiento-2" className="position-relative">
        <div className="container">
            <div className="row">
                <div className="col-md-8 position-relative d-none d-md-block">
                    <img loading="lazy" src={laptopImage} className="img-fluid" />
                    <div className="wistia_embed wistia_async_vsf2thqw1a position-absolute border border-2 border-white"
                        style={{height:"83%", width:"77%", left:"50%", top:"50%", transform:"translate(-49.7%, -50%)"}}>
                        <div className="wistia_swatch style2">
                            <img loading="lazy" src="https://fast.wistia.com/embed/medias/vsf2thqw1a/swatch"
                                style={{filter:"blur(5px)", height:"100%", objectFit:"contain", width:"100%"}} alt=""
                                aria-hidden="true" onLoad={() => {document.querySelector('.wistia_swatch.style2').style.opacity=1}} />
                        </div>
                    </div>
                </div>
                <div className="col-12 position-relative d-block d-md-none p-0">
                    <WistiaProvider >
                        <WistiaPlayer hashedId="hqkfex08p0" />
                    </WistiaProvider>
                   
                    <img src={bgPosicionamiento} ref={imageJungleRef} className="img img-fluid imagenJungla" />
                </div>
                <div className="col-md-4 d-none d-md-flex flex-column justify-content-center">
                    <h4 className="mb-4"><img src={arrowYellow} width="20px" className="me-2" />Somos <strong
                            className="fc-lightBlue">nativos digitales</strong>. Nacimos y crecimos en la jungla
                        digital.</h4>
                    <h4 className="mb-4">Sabemos adaptarnos a su <strong className="fc-lightBlue">ritmo rápido</strong> y
                        siempre cambiante.</h4>
                    <h4>Trabajamos contigo y tu equipo para que<strong className="fc-lightBlue"> tu empresa</strong> no
                        se quede atrás</h4>
                </div>
            </div>
        </div>
    </section>

    )
}