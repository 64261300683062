export const en = {
    nav:{
        contact: 'Contact us'
    },
    conversions: {
        headerTitle: 'Web Design and Development Company in Argentina'
    },
    slidersServices: {
        web: {
            name: 'Web',
            title: 'We Create Your Custom Website',
            paragraph: 'An intuitive, fast, and personalized site',
            button: 'I want to digitize my business'
        },
        eCommerce: {
            name:"Ecommerce",
            title: 'We Help You Take Off with the e-Commerce You Need',
            paragraph: 'We Help You Take Off with the e-Commerce You Need',
            button: 'I Want to Evolve'
        },
        platform: {
            name:"Platform",
            title: 'We develop your platform so that you never stop growing',
            paragraph: 'Automation and process integration',
            button: 'I Want to Grow'
        },
        app: {
            name:"App",
            title: 'We Create Your APP So Distance Isn\'t a Problem',
            paragraph: 'Sell more efficiently and quickly',
            button: 'Ask us'
        }
    },
    partner: {
        weAre: 'WE ARE YOUR',
        partner: 'PARTNER',
        manada: 'PACK',
        trustly: '',
        text: 'We collaborate with <strong>agencies and consulting firms</strong>, helping them to expand their team and capabilities. We provide personalized and collaborative support, with costs tailored to your needs.',
        agencias: 'Agencies and Consultants',
        button: 'Let\'s Be Partners',
        textMobile: "Tenemos un proceso de trabajo especial para"
    },
    technology: 'Technologies',
    technologyClass: 'technology',
    videoPosicionamiento: 'evv5ii7ek1',
    videoPartners: '18evgx6owg',
    contactForm: {
        deskTitle: "Leave us your message or concern.",
        ready: 'Are you ready to evolve in the digital jungle?',
        weRead: '(Contact us, we read carefully, this is not a bot)',
        weRead1:'(We read carefully, this is not a bot)',
        nameAndLast: 'Name and Last Name',
        company: 'Company',
        email: 'Email',
        phone: 'Phone',
        position: 'Position',
        message: 'Leave us your inquiry...',
        required_email: '* Required field and must be a valid email',
        required: '* Required field',
        success: 'Success!',
        send: 'Send',
        weContact: 'We will get in touch shortly so you can be part of this ',
        evolve: 'digital evolution',
        help: 'How can we assist you?',
        option1: 'I want my website to stand out',
        option2: 'I want to update my brand',
        option3: 'I want to increase my market exposure',
        option4: 'I have a digital idea I want to bring to life',
        leaveMessage: 'Dejanos tu mensaje o inquietud',
    }
};