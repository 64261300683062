import { useEffect, useRef } from 'react';
import bgPosicionamiento from './../../../assets/img/bg-posicionamiento.png'
import laptopImage from './../../../assets/img/mock-laptop.svg'
import './SectionThree__styles.css'
import { WistiaProvider, WistiaPlayer } from '@wistia/react-embeds';
import { useTranslation } from 'react-i18next';

export default function SectionThree() {
    const imageJungleRef = useRef()
    const videoId = '3doj7teg2w'

    const { t } = useTranslation()
    const { i18n } = useTranslation()


    const handlePlayEvent = () => {
        imageJungleRef.current.style.opacity = 0
    };

    const targetClass = 'w-big-play-button'

    useEffect(() => {

        let intervalId;

        const checkElement = () => {
            const element = document.querySelector(`.${targetClass}`);
            if (element) {
                // Element with the specific class is found, perform your action here
                element.addEventListener("click", handlePlayEvent)
                clearInterval(intervalId);
            }
        };

        intervalId = setInterval(checkElement, 700); // Adjust the interval duration as needed

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);


    return (
        <section id="posicionamiento-2">
            <div className="container">
                <div className="row">
                    <div className="col-12 position-relative d-none d-md-block">
                        <img loading="lazy" src={laptopImage} className="img-fluid" />
                        {
                             i18n.language === 'es' &&

                        <div className={`wistia_embed wistia_async_${t('videoPosicionamiento')} position-absolute border border-2 border-white style1`}>
                            <div className="wistia_swatch style2">
                                <img loading="lazy" src={`https://fast.wistia.com/embed/medias/${t('videoPosicionamiento')}/swatch`}
                                    className="style3" alt=""
                                    aria-hidden="true" onLoad={() => { document.querySelector('.wistia_swatch.style2').style.opacity = 1 }} />
                            </div>
                        </div>
                        }
                        {
                             i18n.language === 'en' &&

                        <div className={`wistia_embed wistia_async_${t('videoPosicionamiento')} position-absolute border border-2 border-white style1`}>
                            <div className="wistia_swatch style2">
                                <img loading="lazy" src={`https://fast.wistia.com/embed/medias/${t('videoPosicionamiento')}/swatch`}
                                    className="style3" alt=""
                                    aria-hidden="true" onLoad={() => { document.querySelector('.wistia_swatch.style2').style.opacity = 1 }} />
                            </div>
                        </div>
                        }

                    </div>
                    <div className="col-12 position-relative d-block d-md-none p-0 wistia-view">
                        <WistiaProvider >
                            <WistiaPlayer hashedId="3doj7teg2w" />
                        </WistiaProvider>
                        <div className="wistia_responsive_padding">
                            <div className="wistia_responsive_wrapper">
                                <div className="wistia_embed wistia_async_3doj7teg2w videoFoam=true style4">
                                    <div className="wistia_swatch style5">
                                        <img loading="lazy" src="https://fast.wistia.com/embed/medias/3doj7teg2w/swatch"
                                            className="style6"
                                            alt="" aria-hidden="true" onLoad={() => { document.querySelector('.wistia_swatch.style5').style.opacity = 1 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src={bgPosicionamiento} ref={imageJungleRef} className="img img-fluid imagenJungla" />
                    </div>
                </div>
            </div>
        </section>
    )
}