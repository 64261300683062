import iconChimpa from './../../../assets/img/icon-chimpance.svg'
import parallax03 from './../../../assets/img/bg-parallax-03.svg'
import parallax04 from './../../../assets/img/bg-parallax-04.png'

export default function SectionEight(){
    return(
        <section id="contactanos" className="d-none d-md-flex position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mb-5 mb-md-0">
                        <h2 className="fc-lightBlue mb-5">Hace más de 8 años acompañamos la evolución digital de empresas
                        </h2>
                        <a href="#" className="btn btn-outline-primary-icon mt-4 d-inline-flex align-items-lg-center">
                            Contactanos
                            <img loading="lazy" src={iconChimpa} />
                        </a>
                    </div>
                </div>
            </div>
            <img loading="lazy" alt="background" src={parallax03} className="img-03 rellax"
                data-rellax-speed=".2" />
            <img loading="lazy" src={parallax04} className="img-04" />
        </section>
    )
}