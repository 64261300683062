import {
  AltaCuchaPortada,
  AltaCuchaPortadaMobile,
  AnaliticaPortada,
  AnaliticaPortadaMobile,
  Antes,
  BgPortfolioBranding,
  BgPortfolioBrandingMobile,
  BgPortfolioMkt,
  BgPortfolioMktMobile,
  BultakoPortada,
  BultakoPortadaMobile,
  BultakoMobile,
  DelGredalPortda,
  DelGredalPortdaMobile,
  Despues,
  DeliveryPortadaMobile,
  EnmascararGrupo235Mobile,
  GusEspadaPortada,
  GusEspadaPortadaMobile,
  LinetecPortada,
  LinetecPortadaMobile,
  PapelesPortada,
  PapelesPortadaMobile,
  RioSegundoPortada,
  RioSegundoPortadaMobile,
  SingularesPortada,
} from "./../../../assets/img/branding/index";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "../../desarrollo-web/section_2/styles.css";
import "../../desarrollo-web/section_2/slick.css";
import chimpaImg from "../../../assets/img/monito-mobile.png";
import { Link } from "react-router-dom";

export default function SectionTwoResponsive() {
  const images = [
    { img: EnmascararGrupo235Mobile, tipo: "Branding", titulo: "Singulares", url: "/branding/singulares" },
    { img: DelGredalPortdaMobile, tipo: "Branding", titulo: "Del Gredal",url:"/branding/del-gredal" },
    {
      img: AltaCuchaPortadaMobile,
      tipo: "Identidad Visual",
      titulo: "Alta Cucha",
      url:"/branding/alta-cucha"
    },
    { img: LinetecPortadaMobile, tipo: "Branding", titulo: "Linetec",url:"/branding/linetec" },
    {
      img: AnaliticaPortadaMobile,
      tipo: "Identidad Visual",
      titulo: "Analitica",
      url:"/branding/analitica-427"
    },
    {
      img: RioSegundoPortadaMobile,
      tipo: "Branding",
      titulo: "Municipalidad Rio II",
      url:"/branding/municipalidad-rio-II"
    },
    {
      img: GusEspadaPortadaMobile,
      tipo: "Identidad Visual",
      titulo: "Gus Espada",
      url:"/branding/gus-espada"
    },
    { img: BultakoPortadaMobile, tipo: "Identidad Visual", titulo: "Bultako", url:"/branding/bultako" },
    { img: PapelesPortadaMobile, tipo: "Identidad Visual", titulo: "Papeles.net", url:"/branding/papeles-net" },
    { img: DeliveryPortadaMobile, tipo: "Identidad Visual", titulo: "Delivery Wap", url:"/branding/delivery-wap" },
  ];
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const carouselRef = useRef(null);

  const handleTabClick = (slideIndex) => {
    carouselRef.current.slickGoTo(slideIndex);
  };

  const carouselOptions = {
    items: 1,
    loop: true,
    margin: 10,
    autoplay: false,
    speed: 500,
    nav: false,
    arrows: false,
    dots: true,
    dotsClass: "carousel-branding-dots",
    afterChange: (current) => setActiveSlideIndex(current),
    appendDots: (dots) => <ul className="dots-slider-list">{dots}</ul>,
    customPaging: (i) => <span className="dot-slider-mobile"></span>,
  };

  return (
    <section
      id="slider-desarrollo-mobile"
      className="position-relative d-block d-md-none"
      style={{ maxHeight: "100vh", paddingTop: "5%" }}
    >
      <div className="container" style={{ marginTop: "5%" }}>
        <div class="col-md-6 d-flex flex-row flex-wrap mb-3 " style={{ paddingLeft:"15vw"}}>
          
            <img
              src={chimpaImg}
              class="align-middle"
              width="40px"
              height="40px"
              alt="chimpance"
            />
            <p
              className=""
              style={{ fontSize: "18px", margin: " 2% 0% 2% 3%" }}
            >
              Chimpancé Digital
            </p>
          
        </div>
        <Slider ref={carouselRef} {...carouselOptions} style={{position:"relative"}}>
          {images.map((obj, i) => {
            return (
              <div className="item d-flex justify-content-center" key={i}>
                <div className="image-overlays">
                  <img
                    src={obj.img}
                    alt={`Imagen ${i + 1}`}
                    className="img-fluid rounded"
                    id="img-carousel-branding"
                  />
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <h3>{obj.tipo}</h3>

                    <div className="d-flex justify-content-between">
                      <p>{obj.titulo}</p>
                      <span>
                        <Link
                          to={obj.url}
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="gradientes-branding-carousel"></div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}
