export const galeryProyects = [
  {
    ID: "1",
    PROYECTO: "Gus espada",
    TITULO: "Plataforma de suscripción de cursos y arreglos corales",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Gustavo Espada",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "eCommerce,Plataforma",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS:
      "Pagos-Online, Multimoneda, Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://gusespada.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Plataforma de suscripción de cursos y arreglos corales",
  },
  {
    ID: "2",
    PROYECTO: "Logitrader",
    TITULO: "Plataforma de soluciones logísticas",
    DESCRIPCION:
      "Una empresa ubicada en Miami, quería comunicar a sus potenciales clientes la trayectoria y las soluciones de logisticas que ofrecen al rededor del munodo. Diseñamos una web a medida haciendo focos en los sedrvicios principales, haciendo foco en las seccione de contacto para que se puedan poner en contacto fácil con la empresa, tambien desarrollamos un formulario inteligente que sirve a la empresa como un filtro para cotizar sus servicios. Sitio con (A11) accesiilidad para personas con discapacidad.",
    RURO: "Empresa de logistica",
    CLIENTE: "Marcas y Mercados",
    DESC_DESKTOP: "",
    DESC_MOBILE: "Diseño web con accesibilidad para personas con discapaidad. ",
    TAG: "#UX UI Costomizadas \n#HTML5 #Bootstrap",
    CATEGORIA: "Institucional",
    WEB: "Si",
    MOBILE: "Si",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "Multi-idioma",
    PRIORIDAD: "Alta",
    LINK: "https://chimpance.digital/logitrader/",
    TAG_MOCK: "Empresarial",
    PAIS: "Estados-Unidos",
    AÑO: "2021",
    TAG_IMG:
      "Diseño y desarrollo de página web para Plataforma de soluciones logísticas",
  },
  {
    ID: "3",
    PROYECTO: "Linetec",
    TITULO: "Soluciones técnologicas - Web Institucional",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://linetec.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Soluciones técnologicas - Web Institucional",
  },
  {
    ID: "4",
    PROYECTO: "Taller",
    TITULO: "Sitio web de software, innovación y tecnología",
    DESCRIPCION:
      "Taller es una empresa de tecnologia  Alemania, al momento de contratarnos estaban apsando por un momento expación, y particpando de rondas de ibversión. Necesitaban una web que comunique innovación, experieencia, y tecnologia para generfar un status frente a sus potenciale sinversiones. Desarrollamos una web minimalista. con un diseño funcional al público objetivo, y con un diseño exclusivo mobile. ",
    RURO: "Empresa de Software",
    CLIENTE: "Taller",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "#UX UI Costomizadas \n#HTML5 #Bootstrap",
    CATEGORIA: "Institucional",
    WEB: "Si",
    MOBILE: "Si",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "UX-Mobile",
    PRIORIDAD: "Alta",
    LINK: "https://chimpance.digital/taller/",
    TAG_MOCK: "",
    PAIS: "Alemania",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Sitio web de software, innovación y tecnología",
  },
  {
    ID: "5",
    PROYECTO: "Pablo Lavezzari",
    TITULO: "Sitio web de exposición de obras artísticas",
    DESCRIPCION:
      "Pablo Lavezari es un artista córdobes. El desafío que nos planteó Cinergia fue montar una plataforma donde se puedan lucir las obras del artistas y estas causen un impacto visual al usaurio al momento de ingresar a la web. Desarrollamos un sitio enfocado en lo visual, un sitio que genera un interés e interpela al usuario que ingresa al sitio, genera curiosidad de conocer más sobre el airtista. El sitio está capacitado para coneter muchos videos y fotos y funcioar bien, también cuenta con un blog y un portfolio de proyectos auto administrable. \nLa web tiene un Premio Fepi 2021",
    RURO: "",
    CLIENTE: "Cinergia Estudio Creativo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "#Wordpress#UX #UI",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://pablolavezzari.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG:
      "Diseño y desarrollo de página web para Sitio web de exposición de obras artísticas",
  },
  {
    ID: "6",
    PROYECTO: "Del Gredal",
    TITULO: "Bodega: historia y productos",
    DESCRIPCION:
      "Del Gredal es una bodega en pleno Cerro Colorado de la pcia de Córdoba, ubicando sobre el antiguo camino real. Es una bodega con historia, eso es lo que quisieron trasmitir en el sitio web. El desafio de Cinergia fue plasmar el nuevo Branding de la marca y contar su historia en la web. Diseñamos una web institucional donde plasmamos el espiritu de la marca, sus colores, tipografias de modo tal que fue un éxito y obtuvo un premio Eikon 2021. El sitio cuenta con secciones donde se muestra la historia de la marca, se hizo incapie en los vinos, en cada variedad y en su proceso. ",
    RURO: "Bodega",
    CLIENTE: "Cinergia Estudio Creativo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "#Wordpress#Blog ",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://delgredal.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG:
      "Diseño y desarrollo de página web para Bodega: historia y productos",
  },
  {
    ID: "7",
    PROYECTO: "ICES",
    TITULO: "Plataforma de eLearning",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "ICES",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional,eCommerce,Plataforma,eLearning",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS:
      "Pagos-Online, Multimoneda, Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://icesweb.org/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Plataforma de eLearning",
  },
  {
    ID: "8",
    PROYECTO: "FUCA",
    TITULO: "Fundación de arte ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Di Cuore",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media - Baja",
    LINK: "https://fucaarte.org/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Fundación de arte ",
  },
  {
    ID: "9",
    PROYECTO: "FF",
    TITULO: "Plataforma de gestión de ciudadanos ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "MCDG",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Plataforma",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://centrofamiliarvirtual.fundaciondelasfamilias.cl/",
    TAG_MOCK: "",
    PAIS: "Chile",
    AÑO: "2021",
    TAG_IMG:
      "Diseño y desarrollo de página web para Plataforma de gestión de ciudadanos ",
  },
  {
    ID: "10",
    PROYECTO: "AQUA",
    TITULO: "Tecnología agropecuaria",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "MCDG",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://aqua.capital/",
    TAG_MOCK: "",
    PAIS: "Brasil",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Tecnología agropecuaria",
  },
  {
    ID: "11",
    PROYECTO: "Bienal ",
    TITULO: "Galeria de Arte Virtual",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Di Cuore",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Plataforma",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://bienal.fucaarte.org/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Galeria de Arte Virtual",
  },
  {
    ID: "12",
    PROYECTO: "Aeromas ",
    TITULO: "Vuelos en Jet privados",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "22DG",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://aeromas.com/",
    TAG_MOCK: "",
    PAIS: "Uruguay",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para Vuelos en Jet privados",
  },
  {
    ID: "13",
    PROYECTO: "Aeromas back",
    TITULO: "",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "",
    WEB: "",
    MOBILE: "",
    MOCK: "Falta",
    LENGUAJE: "",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media - Baja",
    LINK: "https://online-tools.chimpance.digital/aircraft-online-tools/aircraft-comparison",
    TAG_MOCK: "",
    PAIS: "Estados-Unidos",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para ",
  },
  {
    ID: "14",
    PROYECTO: "Esa",
    TITULO: "Agencia de Marketing Digital",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "ESA",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://www.marketingesa.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Agencia de Marketing Digital",
  },
  {
    ID: "15",
    PROYECTO: "Marian",
    TITULO: "Consultora de Marcas",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Marian",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, Laravel",
    CARACTERISTICAS: "Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://www.marianpd.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Consultora de Marcas",
  },
  {
    ID: "16",
    PROYECTO: "Tres 51",
    TITULO: "Empresa de Retail",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Tres 51",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "Multi-idioma",
    PRIORIDAD: "Baja",
    LINK: "https://tres51.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Empresa de Retail",
  },
  {
    ID: "17",
    PROYECTO: "Efitech",
    TITULO: "Empresa de Tecnología para la ganadería",
    DESCRIPCION:
      "Una empresa de Softaeare para el agro necesitaba el diseño de un sitio web para comunicar y vender todos sus servivios. El desarrollo de la página web cuenta una experiencia de usaurio personalizada para guiar al usuario al contacto. Un blog con todas las ultimas novedades",
    RURO: "Agro",
    CLIENTE: "Villa Nueva",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "#UX #UI #Wordpress#Blog ",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://efitech.villanueva.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Empresa de Tecnología para la ganadería",
  },
  {
    ID: "18",
    PROYECTO: "Grow",
    TITULO: "Ecommerce de Grow Shop ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Global Grow",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Falta",
    LENGUAJE: "",
    CARACTERISTICAS: "UX, UI",
    PRIORIDAD: "Media",
    LINK: "",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "",
    TAG_IMG: "Diseño y desarrollo de página web para Ecommerce de Grow Shop ",
  },
  {
    ID: "19",
    PROYECTO: "Prontophot",
    TITULO: "Tienda Online de impresión fotos digitales",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Prontophot",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS:
      "Pagos-Online, Sistema-de-gestión,Catálago-de-productos, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://prontophot.com/",
    TAG_MOCK: "#Bootstrap#Admin",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Tienda Online de impresión fotos digitales",
  },
  {
    ID: "20",
    PROYECTO: "Xpress Rendering",
    TITULO: "Empresa de renderizado",
    DESCRIPCION:
      "Empresa de Florida que realizada Renders a todo el mundo. Utiliza la web para mostrar sus trabajos y generar leads\nXpress rendering, nos contrató ara rediseñar el sitio, querian un sitio moderno a la altura de la competenica, que comunique el nivel de empresa, y sobre todo querian mostrar la amplia gama de servicios que ofrecen. \nCreamos una web con un diseño sofisticado, con una experiencia de usaurio que guía al visitante por todos los servicios que la empresa ofrece. La web cuenta con un blog autoadministable.",
    RURO: "Arquitectura",
    CLIENTE: "Xpress Rendering",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "#Wordpress#UX #UI #Customizada #Portfolio #Blog",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://xpressrendering.com/",
    TAG_MOCK: "",
    PAIS: "Estados-Unidos",
    AÑO: "2020",
    TAG_IMG: "Diseño y desarrollo de página web para Empresa de renderizado",
  },
  {
    ID: "23",
    PROYECTO: "Bicis",
    TITULO: "Congreso intenacional de bicis ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Guillermo Liverotti",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://combici.org/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Congreso intenacional de bicis ",
  },
  {
    ID: "24",
    PROYECTO: "ECO",
    TITULO: "Fundación de reciclaje ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "ECO Inclusión",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional,eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Nuevo mock",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS:
      "Pagos-Online, Multimoneda, Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://chimpance.digital/eco/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG: "Diseño y desarrollo de página web para Fundación de reciclaje ",
  },
  {
    ID: "25",
    PROYECTO: "Librematica",
    TITULO: "Libreria Online",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Freiberg SACI",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS: "Pagos-Online, Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para Libreria Online",
  },
  {
    ID: "26",
    PROYECTO: "Franco Fit",
    TITULO: "Sitio eCommerce",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional,eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Suiza",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para No funciona",
  },
  {
    ID: "27",
    PROYECTO: "Ralseff",
    TITULO: "Venta de cursos",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Ralseff",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media",
    LINK: "https://ralseff.com/cursosecretariado/",
    TAG_MOCK: "#Onepage",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Venta de cursos",
  },
  {
    ID: "28",
    PROYECTO: "Wallace",
    TITULO: "Academia de Inglés",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Wallace",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Falta",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media - Baja",
    LINK: "https://chimpance.digital/wallace",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Academia de Inglés",
  },
  {
    ID: "29",
    PROYECTO: "Aprovit",
    TITULO: "Empresa de telecomunicaciones ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Inlobe SRL",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, Laravel",
    CARACTERISTICAS: "Mapa-interactivo, Multi-idioma, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://www.approve-itsa.com/",
    TAG_MOCK: "",
    PAIS: "Estados-Unidos",
    AÑO: "2018",
    TAG_IMG:
      "Diseño y desarrollo de página web para Empresa de telecomunicaciones ",
  },
  {
    ID: "30",
    PROYECTO: "Yo mamá",
    TITULO: "Cursos de pre-parto",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Yo Mamá",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://chimpance.digital/yomama/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG: "Diseño y desarrollo de página web para Cursos de pre-parto",
  },
  {
    ID: "31",
    PROYECTO: "America gis",
    TITULO: "Web institucional",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Empresa de rastreo satelital",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://chimpance.digital/americagis/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para No funciona",
  },
  {
    ID: "32",
    PROYECTO: "JM Grop",
    TITULO: "Plataforma de alquiler de departamentos temporrarios en Brasil ",
    DESCRIPCION:
      "Empresa que alquila departamentos temporrarios en Brasil. Multiidioma y multidivisa. Tiene un admiistrador para gestionar los departamentos, agregar fotos, precios. Y se puede poner un solo precio en dolar y el sistema calcula el precio dependiendo la moneda. Se pueden hacer las reservas y pagos por la misma plataforma. Es un eCommerce alquiler y reserva de departamentos",
    RURO: "",
    CLIENTE: "El Lapiz",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional,Plataforma,eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, Laravel",
    CARACTERISTICAS:
      "Multi-idioma, Multimoneda, Pagos-Online, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://groupjm.com/",
    TAG_MOCK: "",
    PAIS: "Brasil",
    AÑO: "2018",
    TAG_IMG:
      "Diseño y desarrollo de página web para Plataforma de alquiler de departamentos temporrarios en Brasil ",
  },
  {
    ID: "33",
    PROYECTO: "Cognition",
    TITULO: "Inteligencia de neocios",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Business Intelligence",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://cognitionbi.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG: "Diseño y desarrollo de página web para Inteligencia de neocios",
  },
  {
    ID: "34",
    PROYECTO: "Incesped",
    TITULO: "Cesped para chanchas deportivas",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Incesped",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media",
    LINK: "https://incesped.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG:
      "Diseño y desarrollo de página web para Cesped para chanchas deportivas",
  },
  {
    ID: "35",
    PROYECTO: "Clinica Santa lucia",
    TITULO: "Clínica de ojos ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Santa Lucia ",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "",
    PRIORIDAD: "Baja",
    LINK: "https://clinicasantaluciasalta.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para Clínica de ojos ",
  },
  {
    ID: "36",
    PROYECTO: "Craneo",
    TITULO: "Empresa de Software",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Craneo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "One-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://craneo.io/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Empresa de Software",
  },
  {
    ID: "37",
    PROYECTO: "Flug",
    TITULO: "Coworking",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Flug",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "https://chimpance.digital/flug/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Coworking",
  },
  {
    ID: "38",
    PROYECTO: "Renta auto",
    TITULO: "Alquiler de autos",
    DESCRIPCION:
      "Necesitaban una web que sea accesible en diferentes idiomas y monedas por el publico. Diseñamos una web minimalista y fácil de navegar. Empresa de alquiler/rentas de autos en toda argentina. El usuario puede alquilar un auto en una ciudad y devolver en otra. El sistema contembla un administrador donde pueden gestionar los autos, precios, puntos de retiro y devolución. Y hacer las reservas pagando por la plataforma.",
    RURO: "",
    CLIENTE: "Inglobe",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Plataforma,Institucional,eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Nuevo mok",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS: "Pagos-Online, Multi-idioma, Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://www.rentautoargentina.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Alquiler de autos",
  },
  {
    ID: "27",
    PROYECTO: "Ralseff",
    TITULO: "Plataforma integral para cursos online",
    DESCRIPCION:
      "Instituto educativo que vende cursos cortos. En la plataforma el usuario puede inscribirse al curso, pagar y realizar los cursos por el mismo. Tiene un sistema de cobros por cuotas de financiación propia, el alumno entra y va generando sus pagos mes a mes. Tambien pueden hacer autoevaluaciones por el sistema y generar certificados. El alumno puede ver videos y descaragar material de estudio. El administrador de la plataforma puede crear curso, modulos y subir todo el contenido de cada clase. Hacer un seguimiento de los cobros y los pendientes a pagar. El sistema tiene una API personalizada desarrollada para integrar con mercado pago y que las trasacciones sean trasparentes. Tambien hay un función de que lo alumnos una vez terminado el curso puedan subir sus CV. Este sistema de CV se vende el accesos a responsable de rescursos humanos de empresas, hay dos accesos empresas y postulantes, funciona como una bolsa de trabajo.",
    RURO: "Educación",
    CLIENTE: "Ralseff",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Plataforma,Institucional,eLearning,eCommerce",
    WEB: " ",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS: "Pagos-Online, Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://ralseff.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG:
      "Diseño y desarrollo de página web para Plataforma integral para cursos online",
  },
  {
    ID: "40",
    PROYECTO: "IEDES",
    TITULO: "Fundación júrdica y económica ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "El Lapiz",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://institutoiedes.org/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG:
      "Diseño y desarrollo de página web para Fundación júrdica y económica ",
  },
  {
    ID: "41",
    PROYECTO: "Miterra",
    TITULO: "Empresa Desarrollista",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Grupo Miterra",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "https://grupomiterra.com/",
    TAG_MOCK: "#Wordpress",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Empresa Desarrollista",
  },
  {
    ID: "42",
    PROYECTO: "La cuesta",
    TITULO: "Venta de lotes ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Grupo Miterra",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://lacuestavr.com.ar/",
    TAG_MOCK: "#Bootstrap",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Venta de lotes ",
  },
  {
    ID: "43",
    PROYECTO: "estacion",
    TITULO: "Venta de lotes ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Grupo Miterra",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "https://estdelcarmen.com.ar/",
    TAG_MOCK: "#Bootstrap",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Venta de lotes ",
  },
  {
    ID: "45",
    PROYECTO: "Domo",
    TITULO: "Seguridad electrónica",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Domo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://domoseguridad.com.ar/alarmacomodato/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para Seguridad electrónica",
  },
  {
    ID: "45",
    PROYECTO: "Domo",
    TITULO: "Seguridad electrónica",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Domo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Falta mock",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://domoseguridad.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para Seguridad electrónica",
  },
  {
    ID: "47",
    PROYECTO: "Kendall",
    TITULO: "Academia de Inglés",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Kendall",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "Pagos-Online, Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "http://kendallschool.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2016",
    TAG_IMG: "Diseño y desarrollo de página web para Academia de Inglés",
  },
  {
    ID: "48",
    PROYECTO: "Suplementos",
    TITULO: "eCommerce de productos diatarios",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "eCommerce",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS: "Catálogo-de-productos, Pagos-Online",
    PRIORIDAD: "Media",
    LINK: "",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "",
    TAG_IMG:
      "Diseño y desarrollo de página web para eCommerce de productos diatarios",
  },
  {
    ID: "49",
    PROYECTO: "Toog Helados",
    TITULO: "Fábrica de helados",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Toog Helados ",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Puntos-de-ventas",
    PRIORIDAD: "Media",
    LINK: "http://toogood.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para Fábrica de helados",
  },
  {
    ID: "50",
    PROYECTO: "Everlight",
    TITULO: "Fábrica de mochilas y bolsos ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Freiberg SA",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "",
    PRIORIDAD: "Baja",
    LINK: "http://everlight.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG:
      "Diseño y desarrollo de página web para Fábrica de mochilas y bolsos ",
  },
  {
    ID: "51",
    PROYECTO: "Freiberg",
    TITULO: "Libreria Mayorista",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Freiberg SA",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "https://www.freiberg.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Libreria Mayorista",
  },
  {
    ID: "52",
    PROYECTO: "Congreso psico",
    TITULO: "Congreso de Psicología",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://chimpance.digital/congreso-psicologia/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Congreso de Psicología",
  },
  {
    ID: "53",
    PROYECTO: "Nimbo",
    TITULO: "Startup Tecnológica ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Gastón Avila",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Media",
    LINK: "https://chimpance.digital/nimbo/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Startup Tecnológica ",
  },
  {
    ID: "54",
    PROYECTO: "Orden de la merced",
    TITULO: "Escuela Religiosa",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Di Cuore",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "https://merced.org.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG: "Diseño y desarrollo de página web para Escuela Religiosa",
  },
  {
    ID: "55",
    PROYECTO: "Tecnicos",
    TITULO: "Centro Atómico Bariloche",
    DESCRIPCION: "Centro Atómico Bariloche",
    RURO: "Centro Atómico Bariloche",
    CLIENTE: "Centro Atómico Bariloche",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Centro Atómico Bariloche",
  },
  {
    ID: "56",
    PROYECTO: "Coralt",
    TITULO: "Alquiler de departamentos temporarios",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Inglobe SRL",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS: "Pagos-Online, Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG:
      "Diseño y desarrollo de página web para Alquiler de departamentos temporarios",
  },
  {
    ID: "57",
    PROYECTO: "Meta obra",
    TITULO: "Venta de maquinaria",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Meta Obra",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "",
    PRIORIDAD: "Baja Baja",
    LINK: "http://www.metalobra.com/inicio/",
    TAG_MOCK: "#Wordpress",
    PAIS: "Argentina",
    AÑO: "2016",
    TAG_IMG: "Diseño y desarrollo de página web para Venta de maquinaria",
  },
  {
    ID: "58",
    PROYECTO: "Perikos",
    TITULO: "Panadería ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Inglobe SRL",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "",
    PRIORIDAD: "Baja",
    LINK: "http://panaderiasperikos.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Panadería ",
  },
  {
    ID: "59",
    PROYECTO: "Exploradores",
    TITULO: "Sitio web de exploradores",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Di Cuore",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Baja Baja",
    LINK: "https://eadb.org.ar",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2016",
    TAG_IMG: "Diseño y desarrollo de página web para Sitio web de exploradores",
  },
  {
    ID: "60",
    PROYECTO: "Administracion",
    TITULO: "Gestión de barrios cerrados ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Lezama ",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "https://chimpance.digital/administraciongc/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2015",
    TAG_IMG:
      "Diseño y desarrollo de página web para Gestión de barrios cerrados ",
  },
  {
    ID: "61",
    PROYECTO: "Escarabajo",
    TITULO: "Venta de muebles ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja Baja",
    LINK: "https://chimpance.digital/escarabajo/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2015",
    TAG_IMG: "Diseño y desarrollo de página web para Venta de muebles ",
  },
  {
    ID: "75",
    PROYECTO: "Spada",
    TITULO: "Página web de un videojuego",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Gustavo Sanaria",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS:
      "Pagos-Online, Multi-idioma, Multimoneda, Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://spadahunts.com/",
    TAG_MOCK: "",
    PAIS: "Estados-Unidos",
    AÑO: "2017",
    TAG_IMG:
      "Diseño y desarrollo de página web para Página web de un videojuego",
  },
  {
    ID: "63",
    PROYECTO: "CEDAC",
    TITULO: "Centro de estetica",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "CEDAC",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "https://cedacderma.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2016",
    TAG_IMG: "Diseño y desarrollo de página web para Centro de estetica",
  },
  {
    ID: "64",
    PROYECTO: "Expo rural",
    TITULO: "Web de exposición rural",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Asociación Rural de Huinca ",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "https://chimpance.digital/exporural/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2016",
    TAG_IMG: "Diseño y desarrollo de página web para Web de exposición rural",
  },
  {
    ID: "65",
    PROYECTO: "Claro",
    TITULO: "Promoción de portabilidad ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Claro",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "https://chimpance.digital/claro/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2016",
    TAG_IMG:
      "Diseño y desarrollo de página web para Promoción de portabilidad ",
  },
  {
    ID: "67",
    PROYECTO: "Cinergia web",
    TITULO: "Productora audiovisual ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Cinergia Estudio Creativo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS: "Blog, Portfolio, Multi-idioma, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://cinergiaestudiocreativo.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para Productora audiovisual ",
  },
  {
    ID: "68",
    PROYECTO: "Decolores",
    TITULO: "Escuela Montessori",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "De Colores ",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://decoloresdc.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para Escuela Montessori",
  },
  {
    ID: "69",
    PROYECTO: "Singulares",
    TITULO: "Empresa de Recursos Humanos",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Cinergia Estudio Creativo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://singulares.com/",
    TAG_MOCK: "",
    PAIS: "Chile",
    AÑO: "2022",
    TAG_IMG:
      "Diseño y desarrollo de página web para Empresa de Recursos Humanos",
  },
  {
    ID: "70",
    PROYECTO: "The weed shool",
    TITULO: "Sitio eLearning",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "The weed school",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional,eLearning",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS:
      "Pagos-Online, Inscripciones, Cursos, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para No funciona",
  },
  {
    ID: "71",
    PROYECTO: "Orishinal",
    TITULO: "Agencia de Influencers ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "MCDG",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Portfolio, Multi-idioma, Autoadministrable ",
    PRIORIDAD: "Media",
    LINK: "https://orishinal.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Agencia de Influencers ",
  },
  {
    ID: "73",
    PROYECTO: "Arvan",
    TITULO: "Cursos Online de estética",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Arvan",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Plataforma,eLearning",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS:
      "Pagos-Online, Inscripciones, Cursos, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://arvancapacitaciones.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG: "Diseño y desarrollo de página web para Cursos Online de estética",
  },
  {
    ID: "74",
    PROYECTO: "BHI",
    TITULO: "Agencia de viajes mayoristas",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "BHI",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Plataforma",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Angular, Laravel, PHP, Bootstrap",
    CARACTERISTICAS: "Autoadministrable, Gestión-de-clientes",
    PRIORIDAD: "Alta",
    LINK: "https://app.bhiviajes.com.ar/home/dashboard",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG:
      "Diseño y desarrollo de página web para Agencia de viajes mayoristas",
  },
  {
    ID: "75",
    PROYECTO: "Celina Espada",
    TITULO: "Cursos online de maquillaje ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Celina Spada",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional,eLearning",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS:
      "Pagos-Online, Inscripciones, Cursos, Blog, Autoadministrable, UX, UI",
    PRIORIDAD: "Baja",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG:
      "Diseño y desarrollo de página web para Cursos online de maquillaje ",
  },
  {
    ID: "76",
    PROYECTO: "Satelite",
    TITULO: "Empresa de satelites",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Cinergia Estudio Creativo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Alta",
    LINK: "https://grupoandesat.com/satelite-andesat1/",
    TAG_MOCK: "",
    PAIS: "Perú",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para Empresa de satelites",
  },
  {
    ID: "78",
    PROYECTO: "Bruno Neff",
    TITULO: "Web Portfolio profesional ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Buro Neff",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "http://xn--broneff-n2a.ch/",
    TAG_MOCK: "",
    PAIS: "Suiza",
    AÑO: "2018",
    TAG_IMG:
      "Diseño y desarrollo de página web para Web Portfolio profesional ",
  },
  {
    ID: "79",
    PROYECTO: "Salud y vida",
    TITULO: "Cursos Onlline de Salud",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Falta mock",
    LENGUAJE: "Wordpress, Woocomerce",
    CARACTERISTICAS:
      "Pagos-Online, Inscripciones, Cursos, Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2020",
    TAG_IMG: "Diseño y desarrollo de página web para Cursos Onlline de Salud",
  },

  {
    ID: "81",
    PROYECTO: "up Lisboa",
    TITULO: "Agencia de SEO",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "",
    WEB: "",
    MOBILE: "",
    MOCK: "Falta mock",
    LENGUAJE: "",
    CARACTERISTICAS: "UX, UI",
    PRIORIDAD: "Baja",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Agencia de SEO",
  },
  {
    ID: "82",
    PROYECTO: "Torre fluss",
    TITULO: "Desarrollo Inmobiliario ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Torre Fluss",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Falta mock",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "https://chimpance.digital/fluss/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2017",
    TAG_IMG: "Diseño y desarrollo de página web para Desarrollo Inmobiliario ",
  },
  {
    ID: "83",
    PROYECTO: "Vitabiosa",
    TITULO: "Venta de productos dietarios ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media",
    LINK: "https://vitabiosa.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2022",
    TAG_IMG:
      "Diseño y desarrollo de página web para Venta de productos dietarios ",
  },
  {
    ID: "84",
    PROYECTO: "Costa Esmelada",
    TITULO: "Gestión de obras para country ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "MCDG",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Plataforma",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "React, Laravel",
    CARACTERISTICAS: "Gestión-de-proyectos, Autoadministrable, Chat-interno",
    PRIORIDAD: "Alta",
    LINK: "",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2022",
    TAG_IMG:
      "Diseño y desarrollo de página web para Gestión de obras para country ",
  },
  {
    ID: "85",
    PROYECTO: "COPA",
    TITULO: "Colegio de Agrimensores ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "COPA SUR",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional,Plataforma",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS: "Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://copa.org.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para Colegio de Agrimensores ",
  },
  {
    ID: "86",
    PROYECTO: "Cuentos Chile",
    TITULO: "Cuentos infantiles",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media",
    LINK: "No funciona",
    TAG_MOCK: "",
    PAIS: "Chile",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para Cuentos infantiles",
  },
  {
    ID: "87",
    PROYECTO: "Ofarrel",
    TITULO: "Consignataria ganadera",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Cinergia Estudio Creativo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "",
    PRIORIDAD: "Alta",
    LINK: "https://www.ivanofarrell.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para Consignataria ganadera",
  },
  {
    ID: "88",
    PROYECTO: "CRE",
    TITULO: "Resistencias Eléctricas",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "CRE SRL",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Baja",
    LINK: "https://cordobaresistencias.com.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2015",
    TAG_IMG: "Diseño y desarrollo de página web para Resistencias Eléctricas",
  },
  {
    ID: "89",
    PROYECTO: "Amerian",
    TITULO: "Hotel 5 Estrellas",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Inglobe SRL",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS:
      "Pagos-Online, Multi-idioma, Multimoneda, Inscripciones, Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://www.amerian.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Hotel 5 Estrellas",
  },
  {
    ID: "90",
    PROYECTO: "Popatik",
    TITULO: "Estudio de diseño y comunicación",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Popatik",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Portfolio, Multi-idioma, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://popatik.com/",
    TAG_MOCK: "",
    PAIS: "PaisVazco",
    AÑO: "2022",
    TAG_IMG:
      "Diseño y desarrollo de página web para Estudio de diseño y comunicación",
  },
  {
    ID: "91",
    PROYECTO: "Paraná Logistica",
    TITULO: "Empresa de trasportes martitimos ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "MCDG",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2022",
    TAG_IMG:
      "Diseño y desarrollo de página web para Empresa de trasportes martitimos ",
  },
  {
    ID: "93",
    PROYECTO: "Venturi",
    TITULO: "Empresa de respuestos hidráulicos ",
    DESCRIPCION:
      "Empresa de Córdoba al mundo, unica en latinoamerica en la fabricación Olehidraulica. Utilizan la web para abrir nuevos mercados y como posicioanamiento. La web tiene un Buscador inteligente de productos \nUna empresa familiar de muchos años, querian renovarse y tener un sitio actualizado. Necesitaban mostrar sus productosl, y que los usuairos encuentren rápido el producto que buscan. Hicimos un buscador inteligente. La web está en 3 idiomas ",
    RURO: "Fabrica",
    CLIENTE: "Venturi Hnos. SA",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "#Bostratp #Laravel ",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Bootstrap, PHP, Laravel",
    CARACTERISTICAS:
      "Autoadministrable, Catálogo-y-buscador-de-productos, Multi-idioma",
    PRIORIDAD: "Alta",
    LINK: "https://venturi.chimpance.digital/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG:
      "Diseño y desarrollo de página web para Empresa de respuestos hidráulicos ",
  },
  {
    ID: "94",
    PROYECTO: "Tribunal de cuentas",
    TITULO: "Plataforma de gestión de trámites y consultas",
    DESCRIPCION:
      "Tribunal de cuentas de la pcia de córdoba. Tenian la necesidad de general una plataforma dónde la comunidad pueda ser guiada facilmente a los tramites y consultas frecuentes",
    RURO: "Gobierno",
    CLIENTE: "Tribunal de cuentas de Córdoba",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "Listo",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Alta",
    LINK: "https://www.tcpcordoba.gov.ar/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2019",
    TAG_IMG:
      "Diseño y desarrollo de página web para Plataforma de gestión de trámites y consultas",
  },
  {
    ID: "95",
    PROYECTO: "Everlight",
    TITULO: "Fábrica de mochilas y bolsos ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Freiberg SA",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Landing-page",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "One-page",
    PRIORIDAD: "Baja",
    LINK: "http://everlight.com.ar/representar.html",
    TAG_MOCK: "#Lead",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG:
      "Diseño y desarrollo de página web para Fábrica de mochilas y bolsos ",
  },
  {
    ID: "96",
    PROYECTO: "Intekra ",
    TITULO: "",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Inglobe SRL",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://www.itecra.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2021",
    TAG_IMG: "Diseño y desarrollo de página web para ",
  },
  {
    ID: "98",
    PROYECTO: "Aisberg",
    TITULO: "Intelligent Feeding",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Cinergia Estudio Creativo",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Bootstrap, Laravel",
    CARACTERISTICAS: "",
    PRIORIDAD: "Alta",
    LINK: "https://aisberg.cl/",
    TAG_MOCK: "",
    PAIS: "Chile",
    AÑO: "2022",
    TAG_IMG: "Diseño y desarrollo de página web para Intelligent Feeding",
  },
  {
    ID: "99",
    PROYECTO: "Popeye",
    TITULO: "Libería Mayorista - App de pedidos ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Popeye Libería ",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "APP",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "React, Laravel",
    CARACTERISTICAS: "Sistema-de-pedidos",
    PRIORIDAD: "Media",
    LINK: "",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2023",
    TAG_IMG:
      "Diseño y desarrollo de página web para Libería Mayorista - App de pedidos ",
  },
  {
    ID: "100",
    PROYECTO: "Teefy",
    TITULO: "Soluciones odontológicas 100% digitales",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Teffy",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Wordpress",
    CARACTERISTICAS: "",
    PRIORIDAD: "Media",
    LINK: "https://chimpance.digital/teefy/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG:
      "Diseño y desarrollo de página web para Soluciones odontológicas 100% digitales",
  },
  {
    ID: "101",
    PROYECTO: "Panacea",
    TITULO: "Hotel Boutique ",
    DESCRIPCION: "",
    RURO: "",
    CLIENTE: "Panacea ",
    DESC_DESKTOP: "",
    DESC_MOBILE: "",
    TAG: "",
    CATEGORIA: "Institucional",
    WEB: "",
    MOBILE: "",
    MOCK: "",
    LENGUAJE: "Bootstrap",
    CARACTERISTICAS: "Blog, Autoadministrable",
    PRIORIDAD: "Media",
    LINK: "https://www.panaceahotel.com/",
    TAG_MOCK: "",
    PAIS: "Argentina",
    AÑO: "2018",
    TAG_IMG: "Diseño y desarrollo de página web para Hotel Boutique ",
  },
];
const categories = {};
const getCategories = (categoryArray, categoryName) => {
  categoryArray.forEach((category) => {
    if (categories[categoryName] && category !== "") {
      if (!categories[categoryName].includes(category))
        categories[categoryName].push(category);
    } else {
      categories[categoryName] = [category];
    }
  });
};

const createTagsArray = (tags) => {
  let addOthers = tags === "" ? (tags = "Otros") : tags;
  let tagsArray = addOthers.replaceAll(/\s/g, "").split(",");
  return tagsArray;
};

/* limpio y estandarizo el JSON */
const trasnformGaleryJson = (galeryJson) => {
  galeryJson.forEach((proyect) => {
    proyect.CATEGORIA = createTagsArray(proyect.CATEGORIA);
    proyect.LENGUAJE = createTagsArray(proyect.LENGUAJE);
    proyect.CARACTERISTICAS = createTagsArray(proyect.CARACTERISTICAS);
    proyect.PROYECTO = proyect.PROYECTO.trim();

    /* agregando elementos adicionales */
    proyect.IMG_SRC = `${proyect.ID}.png`;
    proyect.IMG_COUNTRY = `${proyect.PAIS ? proyect.PAIS + ".svg" : ""}`;
    proyect.GROUPS = JSON.stringify(
      proyect.CARACTERISTICAS.concat(proyect.LENGUAJE, proyect.CATEGORIA)
    );

    /* aca puedo ir agregando las categorias que necesito y sus titulos */
    getCategories(proyect.CATEGORIA, "Tipos de Web");
    getCategories(proyect.LENGUAJE, "Lenguajes");
    getCategories(proyect.CARACTERISTICAS, "Caracteristicas");
  });
  return galeryJson;
};

export const proyectJson = trasnformGaleryJson(galeryProyects);
export const allCategories = categories;
